// eslint-disable-next-line
import {
  Button,
  Tooltip,
  Col,
  Drawer,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  MultiSelect,
  Paper,
  Select,
  Space,
  Text,
  TextInput,
  rem,
  Stack,
  Modal,
  Flex,
} from "@mantine/core";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import {
  IconClearAll,
  IconPlus,
  IconSearch,
  IconSortDescending,
} from "@tabler/icons-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { Info } from "phosphor-react";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useAppDispatch } from "../../connector/ModuleHook";
import { RootState } from "../../connector/ModuleStore";
import { EmployeeService } from "../../interactor/EmployeeInteractor";
import { isEmpty } from "lodash";
import { UpdateEmployeeRequest } from "../../data/request/UpdateEmployeeRequest";
import { UpdateEmployeeService } from "../../interactor/UpdateEmployeeInteractor";
import { toast } from "react-toastify";
import { UpdateEmployeeResponse } from "../../data/response/UpdateEmployeeResponse";
import { DeleteEmployeeResponse } from "../../data/response/DeleteEmployeeResponse";
import { DeleteEmployeeService } from "../../interactor/DeleteEmployeeInteractor";
import Swal from "sweetalert2";
import { EmployeeResponse } from "../../data/response/EmployeeResponse";

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);
const UsersModal = lazy(() => import("./UsersModal"));

const FooterPic = process.env.PUBLIC_URL + "assets/images/footerpic.png";
const PAGE_SIZE = 10;

interface Employee {
  employeeId: string;
  name: string;
  surname: string;
  phoneNumber: string;
  authorityType: string;
  responsibleFields: {
    name: string;
    id: string;
  }[];
  isRead: boolean;
}

const Users: React.FC = () => {
  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [responsibleField, setResponsibleField] = useState<
    { id: string; name: string }[]
  >([]);
  const [authorization, setAuthorization] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [responsibleFieldId, setResponsibleFieldId] = useState<string[]>([]);

  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState<Employee[]>([]);
  const [nameQuery, setNameQuery] = useState("");
  const [surnameQuery, setSurnameQuery] = useState("");
  const [phoneNumberQuery, setPhoneNumberQuery] = useState("");
  const [titleQuery, setTitleQuery] = useState("");
  const [responsibleFieldsQuery, setResponsibleFieldQuery] = useState("");
  const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);
  const [debouncedSurnameQuery] = useDebouncedValue(surnameQuery, 200);
  const [debouncedPhoneNumberQuery] = useDebouncedValue(phoneNumberQuery, 200);
  const [authQuery, setAuthQuery] = useState("");
  const [debouncedAuthQuery] = useDebouncedValue(authQuery, 200);
  const [debouncedResponsibleFieldsQuery] = useDebouncedValue(
    responsibleFieldsQuery,
    200
  );
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "authorityType",
    direction: "desc",
  });
  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Employee | null>(null);
  const [fetching, setFetching] = useState(true);
  const [isEditFetching, setIsEditFetching] = useState(false);
  const [isDeleteFetching, setIsDeleteFetching] = useState(false);
  const [detailModalText, setDetailModalText] = useState<string>();

  const [employeeId, setEmployeeId] = useState("");
  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [sortedData, setSortedData] = useState<Employee[]>([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setAuthorityName = (authorityType: string) => {
    if (authorityType === "1") {
      return "Yetkili";
    } else if (authorityType === "2") {
      return "Mühendis";
    } else if (authorityType === "3") {
      return "Üretici";
    }
  };

  const authorizations = [
    { value: "1", label: "Yetkili" },
    { value: "2", label: "Mühendis" },
    { value: "3", label: "Üretici" },
  ];

  const handleButtonClick = () => {
    // Yönlendirme işlemini gerçekleştir
    navigate("/users/add");
  };
  const [initialRecords, setInitialRecords] = useState<Employee[]>([]);
  const [secondaryRecords, setSecondaryRecords] = useState<Employee[]>([]);

  const employeeService = useSelector(
    (state: RootState) => state.employeeState.value
  );

  const employeeData = employeeService?.data?.employees;

  const fieldService = useSelector(
    (state: RootState) => state.fieldState.value
  );

  const fieldData = fieldService?.data?.fields;

  const fieldDataName = fieldData?.map((field) => field.fieldName);

  const userNameRef = React.createRef<HTMLInputElement>();
  const userSurnameRef = React.createRef<HTMLInputElement>();
  const responsibleFieldRef = React.createRef<HTMLInputElement>();
  const authorizationRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (userNameRef.current) {
      userNameRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          userSurnameRef.current?.focus();
        }
      });
    }
    if (userSurnameRef.current) {
      userSurnameRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          responsibleFieldRef.current?.focus();
        }
      });
    }
    if (responsibleFieldRef.current) {
      responsibleFieldRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          authorizationRef.current?.focus();
        }
      });
    }
  }, [userNameRef, userSurnameRef, responsibleFieldRef, authorizationRef]);

  const handleEdit = (record: Employee, callback: () => void) => {
    setSelectedRecord(record);
    setUserName(record.name);
    setUserSurname(record.surname);
    setPhoneNumber(record.phoneNumber);
    setAuthorization(record.authorityType);
    setResponsibleField(record.responsibleFields);
    setEmployeeId(record.employeeId);
    callback();
  };

  const openAndSetEditFetchingFalse = () => {
    open();
  };
  const validateField = (
    value: string,
    setValue: (value: string) => void,
    setError: (error: string) => void,
    errorMessage?: string
  ) => {
    if (value.trim() === "") {
      setError(errorMessage || "Bu alanı boş bırakılamaz");
      return false;
    } else {
      setError("");
      setValue(value);
      return true;
    }
  };

  const [isSmallThanXl, setIsSmallThanXl] = useState(false);
  const [isSmallThanLg, setIsSmallThanLg] = useState(false);
  const [isSmallThanMd, setIsSmallThanMd] = useState(false);

  useEffect(() => {
    const mediaQueryXl = window.matchMedia("(max-width: 88em)");
    setIsSmallThanXl(mediaQueryXl.matches);

    const handleResize = () => {
      setIsSmallThanXl(mediaQueryXl.matches);
    };

    mediaQueryXl.addEventListener("change", handleResize);
    return () => mediaQueryXl.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    const mediaQueryLg = window.matchMedia("(max-width: 75em)");
    setIsSmallThanLg(mediaQueryLg.matches);

    const handleResize = () => {
      setIsSmallThanLg(mediaQueryLg.matches);
    };

    mediaQueryLg.addEventListener("change", handleResize);
    return () => mediaQueryLg.removeEventListener("change", handleResize);
  }, []);

  useEffect(() => {
    const mediaQueryMd = window.matchMedia("(max-width: 62em)");
    setIsSmallThanMd(mediaQueryMd.matches);

    const handleResize = () => {
      setIsSmallThanMd(mediaQueryMd.matches);
    };

    mediaQueryMd.addEventListener("change", handleResize);
    return () => mediaQueryMd.removeEventListener("change", handleResize);
  }, []);

  const handleUpdate = () => {
    let isValid = true;

    if (
      !validateField(
        userName,
        setUserName,
        setNameError,
        "Ad alanı boş bırakılamaz"
      )
    ) {
      isValid = false;
    }

    if (
      !validateField(
        userSurname,
        setUserSurname,
        setSurnameError,
        "Soyad alanı boş bırakılamaz"
      )
    ) {
      isValid = false;
    }

    if (!isValid || surnameError || nameError) {
      toast.error("Lütfen Gerekli Alanları Doldurunuz");
      setIsEditFetching(false);
      return;
    }

    dispatch(
      UpdateEmployeeService(
        new UpdateEmployeeRequest({
          firstName: userName || "",
          lastName: userSurname || "",
          employeeId: employeeId || "",
          phoneNumber: {
            countryCode: "+90",
            phoneNumber: phoneNumber,
          },
          authorityType: parseInt(authorization || ""),
          fields: responsibleField.map((field) => ({
            id: field.id,
            name: field.name,
          })),
        })
      )
    ).then((response) => {
      if (response !== undefined) {
        const payload = response.payload as UpdateEmployeeResponse | undefined;
        if (payload?.success) {
          close();
          toast.success("Kullanıcı Güncellendi");
          dispatch(EmployeeService());
          setIsEditFetching(false);
        } else {
          toast.error(payload?.userMessage || "Kullanıcı Güncellenemedi");
          setIsEditFetching(false);
        }
      }
    });
  };

  const handleIsDelete = (id: string) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamayacaksınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      } else {
        setIsDeleteFetching(false);
      }
    });
  };

  const handleDelete = (id: string) => {
    dispatch(DeleteEmployeeService(id)).then((response) => {
      if (response !== undefined) {
        const payload = response.payload as DeleteEmployeeResponse | undefined;
        if (payload?.success) {
          toast.success("Kullanıcı Silindi");
          dispatch(EmployeeService());
          setIsDeleteFetching(false);
        } else {
          toast.error(payload?.userMessage || "Kullanıcı Silinemedi");
          setIsDeleteFetching(false);
        }
      }
    });
  };

  useEffect(() => {
    dispatch(EmployeeService()).then((response) => {
      if (response !== undefined) {
        let payload = response.payload as EmployeeResponse | undefined;
        if (payload?.success) {
          setFetching(false);
        } else {
          setFetching(false);
          toast.error(payload?.userMessage);
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(MyFieldService());
  }, []);

  useEffect(() => {
    if (employeeData && fieldData) {
      const employees = employeeData.map((employee) => {
        return {
          employeeId: employee.id,
          name: employee.firstName || "-",
          surname: employee.lastName || "-",
          phoneNumber: employee.phoneNumber.phoneNumber,
          authorityType: employee.authorityType.toString(),
          responsibleFields: resposibleFields(employee.id, fieldData ?? []),
          isRead: false,
        };
      });
      setInitialRecords(employees);
      setSecondaryRecords(employees);
    }
  }, [employeeData, fieldData]);

  const [triggerSearch, setTriggerSearch] = useState("");
  const handleSearch = () => {
    setTriggerSearch(Math.random().toString(36).substring(7));
  };

  const resposibleFields = (employeeId: string, fieldData: any[]): any => {
    var fieldNames = fieldData
      .filter(
        (field) =>
          field.managers &&
          field.managers.some((m: any) => m.employeeId === employeeId)
      )
      .map((field) => {
        return {
          name: field.fieldName === "" ? "-" : field.fieldName,
          id: field.id,
        };
      });
    return fieldNames;
  };
  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      const shouldResetPage =
        debouncedNameQuery ||
        debouncedSurnameQuery ||
        debouncedPhoneNumberQuery ||
        debouncedResponsibleFieldsQuery ||
        debouncedAuthQuery;

      const currentPage = shouldResetPage ? 1 : page;
      if (shouldResetPage) {
        setPage(currentPage);
      }

      const filteredData = initialRecords.filter(
        ({ name, surname, phoneNumber, authorityType, responsibleFields }) => {
          if (
            debouncedNameQuery !== "" &&
            name &&
            !`${name}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedNameQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedSurnameQuery !== "" &&
            surname &&
            !`${surname}`
              .toLowerCase()
              .includes(debouncedSurnameQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedPhoneNumberQuery !== "" &&
            phoneNumber &&
            !`${phoneNumber}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedPhoneNumberQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedAuthQuery !== "" &&
            setAuthorityName(authorityType) &&
            !`${setAuthorityName(authorityType)}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedAuthQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedResponsibleFieldsQuery !== "" &&
            responsibleField &&
            !responsibleField.some((field) =>
              field.name.split(",").some((name) => name.trim() !== "")
            ) &&
            !responsibleFields.some((field) =>
              field.name
                .trim()
                .toLowerCase()
                .replaceAll("i̇", "i")
                .includes(debouncedResponsibleFieldsQuery.trim().toLowerCase())
            )
          ) {
            return false;
          }

          return true;
        }
      );

      let sortedData = [...filteredData];

      if (sortStatus.columnAccessor === "name") {
        sortedData.sort((a, b) => {
          const valueA = a.name ? a.name : "";
          const valueB = b.name ? b.name : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "surname") {
        sortedData.sort((a, b) => {
          const valueA = a.surname ? a.surname.toLowerCase() : "";
          const valueB = b.surname ? b.surname.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "phoneNumber") {
        sortedData.sort((a, b) => {
          const valueA = a.phoneNumber.toLowerCase();
          const valueB = b.phoneNumber.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "authorityType") {
        sortedData.sort((a, b) => {
          let valueA = a.authorityType
            ? setAuthorityName(a.authorityType)?.toLowerCase()
            : "";
          valueA = valueA || "";
          let valueB = b.authorityType
            ? setAuthorityName(b.authorityType)?.toLowerCase()
            : "";
          valueB = valueB || "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "responsibleFields") {
        sortedData.sort((a, b) => {
          const arrayA = a.responsibleFields
            .map((field) => field.name)
            .join(", ")
            .toLowerCase();

          const arrayB = b.responsibleFields
            .map((field) => field.name)
            .join(", ")
            .toLowerCase();

          for (let i = 0; i < Math.min(arrayA.length, arrayB.length); i++) {
            const trimmedA = arrayA[i].trim();
            const trimmedB = arrayB[i].trim();

            if (!trimmedA || !trimmedB) {
              continue;
            }

            const valueA = trimmedA[0];
            const valueB = trimmedB[0];

            if (valueA !== valueB) {
              return sortStatus.direction === "asc"
                ? valueA.localeCompare(valueB)
                : valueB.localeCompare(valueA);
            }
          }

          return sortStatus.direction === "asc"
            ? arrayA.length - arrayB.length
            : arrayB.length - arrayA.length;
        });
      }

      setSortedData(sortedData);
      const from = (currentPage - 1) * PAGE_SIZE;
      const to = from + PAGE_SIZE;
      setPageData(sortedData.slice(from, to));
    }
  }, [initialRecords, page, sortStatus, triggerSearch]);

  const handleClearFilters = () => {
    setNameQuery("");
    setSurnameQuery("");
    setPhoneNumberQuery("");
    setAuthQuery("");
    setResponsibleFieldQuery("");
    setSortStatus({
      columnAccessor: "authorityType",
      direction: "desc",
    });
    setTimeout(() => {
      setTriggerSearch(Math.random().toString(36).substring(7));
    }, 250);
  };

  const columns = [
    {
      accessor: "name",
      title: "Ad",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ name }: { name: string }) => `${name}`,
      filter: (
        <TextInput
          label="İsim"
          description="Belirtilen adı içeren kullanıcıları ara."
          placeholder="Kullanıcı adı ara..."
          icon={<IconSearch size={16} />}
          value={nameQuery}
          onChange={(e) => setNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: nameQuery !== "",
    },
    {
      accessor: "surname",
      title: "Soyad",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ surname }: { surname: string }) => `${surname}`,
      filter: (
        <TextInput
          label="Soyad"
          description="Belirtilen soyadı içeren kullanıcıları ara."
          placeholder="Kullanıcı soyadı ara..."
          icon={<IconSearch size={16} />}
          value={surnameQuery}
          onChange={(e) => setSurnameQuery(e.currentTarget.value)}
        />
      ),
      filtering: surnameQuery !== "",
    },
    {
      accessor: "phoneNumber",
      title: "Cep Telefonu",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ phoneNumber }: { phoneNumber: string }) => `${phoneNumber}`,
      filter: (
        <TextInput
          label="Telefon Numarası"
          description="Belirtilen telefon numarasına sahip kullanıcıları ara."
          placeholder="Telefon numarası ara..."
          icon={<IconSearch size={16} />}
          value={phoneNumberQuery}
          onChange={(e) => setPhoneNumberQuery(e.currentTarget.value)}
        />
      ),
      filtering: phoneNumberQuery !== "",
    },
    {
      accessor: "authorityType",
      title: "Yetki",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ authorityType }: { authorityType: string }) =>
        `${setAuthorityName(String(authorityType))}`,
      filter: (
        <TextInput
          label="Yetki"
          description="Belirtilen yetkiye sahip kullanıcıları ara."
          placeholder="Yetkilere göre filtrele"
          icon={<IconSearch size={16} />}
          value={authQuery}
          onChange={(e) => setAuthQuery(e.currentTarget.value)}
        />
      ),
      filtering: titleQuery !== "",
    },
    {
      accessor: "responsibleFields",
      title: "Sorumlu Olduğu Tarlalar",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      width: 300,
      ellipsis: true,
      render: ({
        responsibleFields,
      }: {
        responsibleFields: { name: string; id: string }[];
      }) => (
        <td
          style={{
            textAlign: "center",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          {responsibleFields.map((field) => field.name).join(", ").length >
          30 ? (
            <Tooltip label="Detayı Görüntülemek İçin Tıklayınız.">
              <div
                onClick={() =>
                  setDetailModalText(
                    responsibleFields.map((field) => field.name).join(", ")
                  )
                }
              >
                {`${responsibleFields
                  .map((field) => field.name)
                  .join(", ")
                  .substring(0, 30)}...`}
                <span style={{ color: "blue", cursor: "pointer" }}>
                  {" "}
                  Devamı...
                </span>
              </div>
            </Tooltip>
          ) : (
            responsibleFields.map((field) => field.name).join(", ") ?? ""
          )}
        </td>
      ),

      filter: (
        <TextInput
          label="Sorumlu Tarlalar"
          description="Sorumlu olduğu tarlalara göre sahip kullanıcıları ara."
          placeholder="Yetkilere göre filtrele"
          icon={<IconSearch size={16} />}
          value={responsibleFieldsQuery}
          onChange={(e) => setResponsibleFieldQuery(e.currentTarget.value)}
        />
      ),
      filtering: responsibleFieldsQuery !== "",
    },
    {
      accessor: "isRead",
      title: "Düzenle & Sil",
      textAlignment: "left" as DataTableColumnTextAlignment,

      render: (record: Employee, index: number) => (
        <Group spacing={4} position="center" noWrap>
          <Drawer.Root opened={opened} onClose={close} position="right">
            <Drawer.Content
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
                width: 1000,
              }}
              pos={"relative"}
            >
              <LoadingOverlay
                visible={isEditFetching}
                overlayBlur={2}
                loaderProps={{
                  size: "md",
                  color: "#0097c4",
                  variant: "oval",
                }}
                pos={"absolute"}
              />
              <Drawer.Header>
                <Drawer.Title
                  style={{ fontFamily: "Poppins-Light", fontSize: "28px" }}
                >
                  Kullanıcı Güncelle
                </Drawer.Title>
                <Drawer.CloseButton />
              </Drawer.Header>
              <Grid>
                <Col span={12}>
                  <Text
                    pb={rem(8)}
                    style={{
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    {" "}
                    Adı
                  </Text>
                  <Input
                    ref={userNameRef}
                    tabIndex={1}
                    radius={10}
                    placeholder="Kullanıcı Adı Girin"
                    value={userName || ""}
                    onChange={(event) => {
                      setUserName(event.target.value);
                      setNameError("");
                    }}
                    sx={{
                      input: {
                        borderRadius: "32px",
                        height: "48px",
                        borderColor: nameError ? "red" : "#ced4da",
                      },
                    }}
                  />
                  {nameError && (
                    <div
                      style={{
                        color: "red",
                        fontFamily: "Poppins-Medium",
                        fontSize: "12px",
                      }}
                    >
                      {nameError}
                    </div>
                  )}
                </Col>
                <Col span={12}>
                  <Text
                    pb={rem(8)}
                    style={{
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    {" "}
                    Soyadı
                  </Text>
                  <Input
                    ref={userSurnameRef}
                    tabIndex={2}
                    placeholder="Kullanıcı Soyadı Girin"
                    value={userSurname || ""}
                    onChange={(event) => {
                      setUserSurname(event.target.value);
                      setSurnameError("");
                    }}
                    sx={{
                      input: {
                        height: "48px",
                        borderRadius: "32px",
                        borderColor: surnameError ? "red" : "#ced4da",
                      },
                    }}
                  />
                  {surnameError && (
                    <div
                      style={{
                        color: "red",
                        fontFamily: "Poppins-Medium",
                        fontSize: "12px",
                      }}
                    >
                      {surnameError}
                    </div>
                  )}
                </Col>

                <Col span={12}>
                  <Text
                    pb={rem(8)}
                    style={{
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      color: "#000000",
                    }}
                  >
                    Sorumlu Olduğu Tarlalar
                  </Text>
                  <MultiSelect
                    ref={responsibleFieldRef}
                    tabIndex={3}
                    searchable
                    radius={32}
                    className="multi-select-add-user"
                    placeholder="Kullanıcının Sorumlu olduğu tarlaları seçin"
                    data={
                      fieldData?.map((field) => ({
                        id: field.id,
                        value: field.id,
                        label: field.fieldName,
                      })) || []
                    }
                    disabled={
                      selectedRecord?.authorityType === "1" ? true : false
                    }
                    value={
                      responsibleField.map(
                        (field: { id: string }) => field.id
                      ) || []
                    }
                    onChange={(value) => {
                      const fields = value.map((id) => ({
                        id,
                        name:
                          fieldData?.find((field) => field.id === id)
                            ?.fieldName || "",
                      }));
                      setResponsibleField(fields);
                    }}
                    sx={{
                      ".mantine-MultiSelect-input": {
                        padding:
                          responsibleField && responsibleField.length > 3
                            ? "10px"
                            : "0px calc(0.75rem / 1.5)",
                      },
                      ".mantine-MultiSelect-defaultValue": {
                        margin:
                          responsibleField && responsibleField.length > 3
                            ? "6px 4px"
                            : "calc(0.625rem / 2 - 0.125rem) calc(0.625rem / 2)",
                      },
                    }}
                  />
                </Col>

                <Col span={12}>
                  <Text
                    pb={rem(8)}
                    style={{
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      color: "#000000",
                    }}
                  >
                    {" "}
                    Yetki
                  </Text>
                  <Select
                    ref={authorizationRef}
                    placeholder="Kullanıcı Yetkisi Seçin"
                    data={
                      authorizations?.map((auth) => ({
                        value: auth.value,
                        label: auth.label,
                      })) || []
                    }
                    value={authorization || ""}
                    onChange={(value) => setAuthorization(value)}
                    sx={{
                      input: {
                        borderRadius: "32px",
                        height: "48px",
                      },
                    }}
                    disabled
                  />
                  <Space h="20px" />
                </Col>

                <Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#0097c4",
                      width: "338px",
                      borderRadius: "32px",
                      color: "#FFFFFF",
                      fontSize: "12px",
                      fontFamily: "Poppins-Medium",
                      marginBottom: "20px", // Adjusted margin for spacing
                      padding: "11.5px 40px", // Adjusted padding
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setIsEditFetching(true);
                      handleUpdate();
                    }}
                  >
                    Kaydet
                  </Button>

                  <Button
                    style={{
                      width: "338px",
                      borderRadius: "32px",
                      backgroundColor: "#FFFFFF",
                      color: "#000000",
                      border: "0.5px solid #000000",
                      fontFamily: "Poppins-Light",
                      fontSize: "12px",
                      padding: "11.5px 40px", // Adjusted padding
                      textAlign: "center",
                    }}
                    onClick={close}
                  >
                    İptal
                  </Button>
                </Col>
              </Grid>
            </Drawer.Content>
          </Drawer.Root>

          <Button
            variant="outline"
            color="#000000"
            fullWidth
            style={{
              color: "#000000",
              borderColor: "#000000",
              borderRadius: "2rem",
              width: "100%",
              fontWeight: 300,
            }}
            onClick={() => {
              handleEdit(record, openAndSetEditFetchingFalse);
            }}
          >
            <Text>Düzenle</Text>
          </Button>

          <Button
            variant="outline"
            color="#000000"
            fullWidth
            style={{
              color: "#000000",
              borderColor: "#000000",
              borderRadius: "2rem",
              width: "100%",
              fontWeight: 300,
              ...(record.authorityType === "1"
                ? { display: "none" }
                : { display: "block" }),
            }}
            disabled={record.authorityType === "1" ? true : false}
            onClick={() => {
              handleIsDelete(record.employeeId);
              setIsDeleteFetching(true);
            }}
          >
            <Text>Sil</Text>
          </Button>
        </Group>
      ),
    },
  ];
  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Kullanıcılar", href: "#" }]}
        title="Kullanıcılar"
      >
        <>
          <Modal
            opened={detailModalText !== undefined}
            onClose={() => setDetailModalText(undefined)}
            className="custom-modal"
            title="Sorumlu Olduğu Tarlalar"
            centered
            size={"lg"}
            withCloseButton
            styles={{
              content: {
                borderRadius: "2rem",
                padding: ".8rem",
              },
            }}
            sx={{
              ".mantine-Modal-title": {
                fontSize: "20px !important",
                fontWeight: "bold",
              },
            }}
          >
            {detailModalText && <Text>{detailModalText}</Text>}
          </Modal>
          <Suspense fallback={<CustomLoadingOverlay />}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <LoadingOverlay
                visible={isDeleteFetching}
                overlayBlur={2}
                loaderProps={{
                  size: "md",
                  color: "#0097c4",
                  variant: "oval",
                }}
                pos={"absolute"}
              />
              <Grid>
                <Grid.Col md={12} lg={6}>
                  <Group position={isSmallThanLg ? "center" : "left"}>
                    <Info size={isSmallThanXl ? 26 : 32} />

                    <Text
                      style={{
                        fontSize: isSmallThanXl ? "14px" : "16px",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Kullanıcı bilgisini düzenlemek için DÜZENLE butonunu
                      kullanınız.
                    </Text>
                  </Group>
                </Grid.Col>
                <Grid.Col lg={6} md={12}>
                  <Flex justify={"flex-end"}>
                    <Button
                      onClick={handleSearch}
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      <IconSearch height={15} /> Filtreleri Uygula
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={handleClearFilters}
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      <IconClearAll /> Filtreleri Temizle
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                      onClick={handleButtonClick}
                    >
                      <IconPlus /> Kullanıcı Ekle
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Space h="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <DataTable
                    withColumnBorders
                    highlightOnHover
                    minHeight={250}
                    noRecordsText="Kullanıcı Bulunamadı..."
                    className="datatable"
                    records={pageData}
                    columns={columns}
                    totalRecords={sortedData.length}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    fetching={fetching}
                    loaderColor="#0097c4"
                    sortStatus={sortStatus}
                    onSortStatusChange={handleSortStatusChange}
                    sortIcons={{
                      sorted: <IconSortDescending size={14} />,
                      unsorted: <IconSortDescending size={14} />,
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Paper>

            <UsersModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </Suspense>
        </>
      </MainWrapper>
    </Suspense>
  );
};

export default Users;
