import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  Paper,
  rem,
  Group,
  Drawer,
  Flex,
  Box,
  Modal,
  Stack,
  Space,
  Button,
  Text,
  LoadingOverlay,
  Container,
  Input,
  Table,
} from "@mantine/core";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddSurveyCard from "./AddSurveyCard";
import {
  FormBuilderPostData,
  ReactFormBuilder,
  ReactFormGenerator,
} from "react-form-builder2";
import "react-form-builder2/dist/app.css";
import MainWrapper from "../../../../app/common/presentation/view/component/mainWrapper";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  AnswerFormAddService,
  AnswerListFormService,
  FormPublishService,
  FormQuestionAddService,
  FormQuestionDetailService,
  UserPhoneCheckService,
} from "../../interractor/SurveyInterractor";
import { useAppDispatch } from "../../connector/ModuleHook";
import { FormQuestionAddRequest } from "../../data/request/FormQuestionAddRequest";
import { FormQuestionDetailRequest } from "../../data/request/FormQuestionDetailRequest";
import Swal from "sweetalert2";
import { FormPublishRequest } from "../../data/request/FormPublishRequest";
import { FormAnswerAddRequest } from "../../data/request/FormAnswerAddRequest";
import { AnswerFormAddResponse } from "../../data/response/AnswerFormAddResponse";
import { toast } from "react-toastify";
import { AnswerListFormRequest } from "../../data/request/AnswerListFormRequest";
import { useSelector } from "react-redux";
import { RootState } from "../../connector/ModuleStore";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";

const DisplayForm: React.FC = () => {
  let { id, type } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const formFieldEmplyoeeId = queryParams.get("formFieldEmplyoeeId");
  const formFieldVisitorId = queryParams.get("formFieldVisitorId");
  const fieldId = queryParams.get("fieldId");

  const [data, setData] = useState<any>(null);
  const [answersData, setAnswersData] = useState<any[] | null>(null);
  const [singleFromData, setSingleFormData] = useState<any>(null);
  const [singleFromDataLength, setSingleFormDataLength] = useState<any>(null);

  const [singleForm, setSingleForm] = useState<[] | null>(null);
  const [singleFormState, setSingleFormState] = useState(1);

  const [singleFormIds, setSingleFormIds] = useState<string[]>([]);
  const [formCompleted, setFormCompleted] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);

  const [userDetails, setUserDetails] = useState<any>(null);
  const [fetching, setFetching] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const queryPhoneData = useSelector(
    (state: RootState) => state.UserPhoneCheckState.value
  );

  const myFieldService = useSelector(
    (state: RootState) => state.MyFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  const [changeFormData, setChangeFormData] = useState<any>(null);

  const [checkBoxes, setCheckBoxes] = useState<any>(null);

  useEffect(() => {
    if (id && type) {
      loadFormData();
      loadAnswers(id, undefined);
      setSingleFormState(1);
    }
    if (!myFieldData) {
      dispatch(MyFieldService());
    }
  }, [id, type]);

  const loadFormData = async () => {
    try {
      let response = await dispatch(
        FormQuestionDetailService({
          formId: id,
        } as FormQuestionDetailRequest)
      ).unwrap();
      if (response) {
        if (response?.data) {
          if (response.data.formQuestion.publishedJsonData) {
            let jsonData: any = JSON.parse(
              response.data.formQuestion.publishedJsonData
            );
            setData(jsonData);
            if (type === "2" && jsonData.task_data.length > 0) {
              let canAnswerQuestions = jsonData.task_data.filter(
                (item: any) =>
                  item?.canHaveAnswer === true ||
                  item?.isContainer === true ||
                  item?.element === "DatePicker"
              );
              let ids: string[] = [];
              canAnswerQuestions.forEach((question: any) => {
                if (question?.isContainer === true) {
                  question.childItems.forEach((child: string) => {
                    if (
                      ids.indexOf(child) == -1 &&
                      (jsonData.task_data.find((ceL: any) => ceL.id == child)
                        ?.canHaveAnswer === true ||
                        jsonData.task_data.find((ceL: any) => ceL.id == child)
                          ?.element === "DatePicker")
                    ) {
                      ids.push(child);
                    }
                  });
                } else {
                  if (ids.indexOf(question.id) == -1) {
                    ids.push(question.id);
                  }
                }
              });
              setSingleFormIds(ids);
              setSingleFormData(canAnswerQuestions);
              setSingleFormDataLength(ids.length);
            }
            console.log(jsonData);
            return jsonData.task_data;
          } else {
            return [] as any;
          }
        } else {
          return [] as any;
        }
      }
      return [] as any;
    } catch (error) {
      console.error("Error loading form data:", error);
      return [] as any;
    }
  };

  const loadAnswers = async (formId: string, userId?: string) => {
    if (!formFieldEmplyoeeId && !formFieldVisitorId) {
      setAnswersData([]);
      return [] as any;
    }
    try {
      let response = await dispatch(
        AnswerListFormService({
          formId: formId,
          userId: userId,
          formFieldEmployeeId: formFieldEmplyoeeId ? formFieldEmplyoeeId : null,
          formFieldVisitorId: formFieldVisitorId ? formFieldVisitorId : null,
        } as AnswerListFormRequest)
      ).unwrap();
      if (response) {
        if (response?.data?.answerDtos) {
          setAnswersData(null);
          setTimeout(() => {
            setAnswersData(
              response!.data!.answerDtos.map((item: any) => {
                return {
                  value: !item.value ? "" : item.value,
                  name: item.name,
                };
              })
            );
            setCheckBoxes(
              response!
                .data!.answerDtos.filter(
                  (item: any) => item.name.indexOf("checkboxes") > -1
                )
                .map((item: any) => {
                  return {
                    value: !item.value ? "" : item.value,
                    name: item.name,
                  };
                })
            );
          }, 100);
        }
      }

      return [] as any;
    } catch (error) {
      console.error("Error loading form data:", error);
      return [] as any;
    }
  };

  useEffect(() => {
    if (checkBoxes) {
      setTimeout(() => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');

        checkBoxes.forEach((element: any) => {
          if (element.value) {
            element.value.forEach((val: any) => {
              checkboxes.forEach((checkbox: any) => {
                if (checkbox.value === val) {
                  checkbox.checked = true;
                }
              });
            });
          }
        });
      }, 1000);
    }
  }, [checkBoxes]);

  useEffect(() => {
    if (singleFromData && singleFormIds) {
      console.log(singleFromData);
      let formData = singleFromData.find(
        (item: any) => item.id === singleFormIds[singleFormState - 1]
      );
      if (formData?.parentId) {
        formData.parentId = undefined;
        formData.parentIndex = undefined;
      }
      setSingleForm([formData] as any);
    }
  }, [singleFromData, singleFormIds, singleFormState]);

  const handleChange = (formData: any[]) => {
    setChangeFormData(formData);
  };

  const handleSubmit = async (formData: any[]) => {
    try {
      let assignFormRequest: FormAnswerAddRequest = {
        formId: id ?? "",
        data: JSON.stringify(formData),
        formFieldEmployeeId:
          !formFieldEmplyoeeId || formFieldEmplyoeeId === ""
            ? null
            : formFieldEmplyoeeId,
        formFieldVisitorId:
          !formFieldVisitorId || formFieldVisitorId === ""
            ? null
            : formFieldVisitorId,
        formFieldEmployeeState: 2,
      };
      setFetching(true);
      dispatch(AnswerFormAddService(assignFormRequest)).then((response) => {
        if (response !== undefined) {
          let responsePayload = response.payload as
            | AnswerFormAddResponse
            | undefined;
          if (responsePayload?.success) {
            setFetching(false);
            if (singleFormState + 1 > singleFromDataLength) {
              setFormCompleted(true);
              Swal.fire({
                title: "Teşekkürler",
                text: "Form başarı ile tamamlanmıştır.",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#0097c4",
                confirmButtonText: "Tamam",
              }).then((result) => {
                if (result.isConfirmed) {
                  if (formFieldVisitorId && formFieldVisitorId !== "") {
                    navigate(`/myfields/detail?id=${fieldId}`);
                  } else {
                    navigate(`/mytask/detail/${formFieldEmplyoeeId}`);
                  }
                }
              });
            } else {
              setSingleFormState((prev) => prev + 1);
            }
          } else {
            toast.error(responsePayload?.userMessage);
            setFetching(false);
          }
        }
      });

      console.log(formData);
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred during form submission.");
    }
  };

  const saveFormChanges = () => {
    if (!changeFormData) {
      return;
    }
    try {
      let assignFormRequest: FormAnswerAddRequest = {
        formId: id ?? "",
        data: JSON.stringify(changeFormData),
        formFieldEmployeeId:
          !formFieldEmplyoeeId || formFieldEmplyoeeId === ""
            ? null
            : formFieldEmplyoeeId,
        formFieldVisitorId:
          !formFieldVisitorId || formFieldVisitorId === ""
            ? null
            : formFieldVisitorId,
        formFieldEmployeeState: 1,
      };
      setFetching(true);
      dispatch(AnswerFormAddService(assignFormRequest)).then((response) => {
        if (response !== undefined) {
          let responsePayload = response.payload as
            | AnswerFormAddResponse
            | undefined;
          if (responsePayload?.success) {
            setFetching(false);
            if (singleFormState + 1 > singleFromDataLength) {
              setFormCompleted(true);
              toast.success("Form başarı ile kayıt edilmiştir.");
              // .then((result) => {
              //   if (result.isConfirmed) {
              //     navigate("/survey/list");
              //   }
              // });
            } else {
              setSingleFormState((prev) => prev + 1);
            }
          } else {
            toast.error(responsePayload?.userMessage);
            setFetching(false);
          }
        }
      });
    } catch (error) {
      console.error("Error during form submission:", error);
      alert("An error occurred during form submission.");
    }
  };

  useEffect(() => {
    getData();
  }, [queryPhoneData, dispatch]);

  const getData = () => {
    if (queryPhoneData && queryPhoneData.data?.id) {
      const userId = queryPhoneData.data.id;
      setFetching(false);
      setUserDetails(queryPhoneData.data);
      if (id && userId) {
        loadAnswers(id, userId);
      }
    }
  };

  const validatePhoneNumber = (value: string) => {
    const phoneNumberPattern = /^\d{10}$/;
    setIsPhoneNumberValid(phoneNumberPattern.test(value));
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneNumber(value);
    validatePhoneNumber(value);
  };

  const handleSearch = () => {
    if (!isPhoneNumberValid) {
      setIsModalVisible(true);
      return;
    }
    const countryCode = encodeURIComponent("+90");
    dispatch(UserPhoneCheckService({ countryCode, phoneNumber }));
  };

  return (
    <MainWrapper
      breadCrumbs={[
        { title: "Formlar", href: "/survey/list" },
        { title: "Form Görüntüleme", href: "#" },
      ]}
      title={"Form Görüntüleme"}
    >
      <>
        {/* <Paper
          shadow="xs"
          p="xl"
          pt="0"
          className="profile-paper"
          style={{ marginBottom: "20px" }}
        >
          <Container>
            <Space h="lg" />
            <Flex align="center" gap="md">
              <Input
                value={phoneNumber}
                onChange={handleInputChange}
                placeholder="Kullanıcı Telefon Numarası"
                maxLength={10}
                sx={{ width: "300px" }}
              />

              <Button onClick={handleSearch}>Sorgula</Button>
            </Flex>

            {!isPhoneNumberValid && (
              <Text color="red" size="sm">
                Lütfen geçerli bir telefon numarası girin.
              </Text>
            )}

            {userDetails && (
              <div>
                <Text>
                  <b>Kullanıcı Adı:</b> {userDetails.firstName}
                </Text>
                <Text>
                  <b>Kullanıcı Soyadı:</b> {userDetails.lastName}
                </Text>
              </div>
            )}

            <Modal
              opened={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              title="Hata"
            >
              <Text>Geçersiz telefon numarası, lütfen tekrar deneyin.</Text>
            </Modal>
          </Container>
        </Paper> */}
        <Paper
          shadow="xs"
          p="xl"
          pt="0"
          className="profile-paper"
          style={{ maxWidth: "1080px", margin: "0px auto" }}
        >
          <LoadingOverlay
            visible={fetching || false}
            overlayBlur={2}
            loaderProps={{
              size: "md",
              color: "#0097c4",
              variant: "oval",
            }}
          />
          <Space h="20px" />
          {myFieldData && fieldId && (
            <Table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <tr>
                <td style={{ border: "1px solid #ccc", fontWeight: 600 }}>
                  Konum:
                </td>
                <td style={{ border: "1px solid #ccc" }}>
                  {`${
                    myFieldData.find((field) => field.id === fieldId)?.address
                      .city.name
                  } / ${
                    myFieldData.find((field) => field.id === fieldId)?.address
                      .district.name
                  } / ${
                    myFieldData.find((field) => field.id === fieldId)?.address
                      .neighbourhood.name
                  }`}
                </td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", fontWeight: 600 }}>
                  Ada:
                </td>
                <td style={{ border: "1px solid #ccc" }}>{`${
                  myFieldData.find((field) => field.id === fieldId)?.address
                    .block
                }
                  `}</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", fontWeight: 600 }}>
                  Parsel:
                </td>
                <td style={{ border: "1px solid #ccc" }}>{`${
                  myFieldData.find((field) => field.id === fieldId)?.address
                    .parcel
                }
                  `}</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", fontWeight: 600 }}>
                  Üretici Adı:
                </td>
                <td style={{ border: "1px solid #ccc" }}>{`${
                  myFieldData.find((field) => field.id === fieldId)?.farmerName
                }
                  `}</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", fontWeight: 600 }}>
                  Üretici Telefon:
                </td>
                <td style={{ border: "1px solid #ccc" }}>{`${
                  myFieldData.find((field) => field.id === fieldId)
                    ?.farmerPhoneNumber
                }
                  `}</td>
              </tr>
              <tr>
                <td style={{ border: "1px solid #ccc", fontWeight: 600 }}>
                  Ürün:
                </td>
                <td style={{ border: "1px solid #ccc" }}>{`${
                  myFieldData.find((field) => field.id === fieldId)?.seasons
                    ? myFieldData.find((field) => field.id === fieldId)
                        ?.seasons[
                        myFieldData.find((field) => field.id === fieldId)!
                          .seasons.length - 1
                      ].plantName
                    : ""
                }
                  `}</td>
              </tr>
            </Table>
          )}

          <Space h="20px" />
          {data && id && type == "1" && answersData && (
            <>
              <ReactFormGenerator
                form_action={`${process.env.PUBLIC_URL}/path/to/form/submit`}
                form_method="POST"
                task_id={12}
                answer_data={answersData}
                authenticity_token={""}
                data={data.task_data}
                onSubmit={handleSubmit}
                onChange={handleChange}
                skip_validations={false}
                submitButton={
                  <>
                    <Button
                      variant="outline"
                      color="#000000"
                      fullWidth
                      style={{
                        color: "#FFF",
                        borderColor: "#000000",
                        borderRadius: "2rem",
                        width: "120px",
                        fontWeight: 300,
                        backgroundColor: "red",
                      }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      Geri
                    </Button>
                    &nbsp; &nbsp;
                    {(formFieldEmplyoeeId || formFieldVisitorId) && (
                      <>
                        <Button
                          variant="outline"
                          color="#000000"
                          fullWidth
                          style={{
                            color: "#FFF",
                            borderColor: "#000000",
                            borderRadius: "2rem",
                            width: "120px",
                            fontWeight: 300,
                            backgroundColor: "#7169d2",
                          }}
                          onClick={() => {
                            saveFormChanges();
                          }}
                        >
                          Kaydet
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          variant="outline"
                          color="#000000"
                          fullWidth
                          type="submit"
                          style={{
                            color: "#FFF",
                            borderColor: "#000000",
                            borderRadius: "2rem",
                            width: "120px",
                            fontWeight: 300,
                            backgroundColor: "#50aa4c",
                          }}
                        >
                          Tamamla
                        </Button>
                      </>
                    )}
                  </>
                }
                locale="tr"
              />
            </>
          )}

          {singleFromData && singleForm && id && type == "2" && answersData && (
            <>
              <Text weight={600} size={24}>
                {singleFormState}
              </Text>
              <ReactFormGenerator
                form_action={`${process.env.PUBLIC_URL}/path/to/form/submit`}
                form_method="POST"
                task_id={12}
                answer_data={answersData}
                authenticity_token={""}
                data={singleForm}
                onSubmit={handleSubmit}
                submitButton={
                  <Button
                    variant="filled"
                    color="blue"
                    type="submit"
                    style={{
                      marginBottom: "20px",
                      backgroundColor: "#50aa4c",
                      float: "right",
                    }}
                  >
                    İleri
                  </Button>
                }
                locale="tr"
              />
            </>
          )}
          <Space h="10px" />
        </Paper>
      </>
    </MainWrapper>
  );
};

export default DisplayForm;
