import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";
import { ServiceState } from "../../common/data/ServiceState";
import moment from "moment";
import { GetCorporateDashboardResponse } from "../data/response/GetCorporateDashboardResponse";
import { GetCorporateDashboardRequest } from "../data/request/GetCorporateDashboardRequest";

const initialGetCorporateDashboardState: ServiceState<GetCorporateDashboardResponse> =
  {};

export const GetCorporateDashboardService = createAsyncThunk(
  "corporate/Dashboard",
  async (request: GetCorporateDashboardRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().get<GetCorporateDashboardResponse>(
          `corporate/dashboard?PlantId=${request.plantId}&CityId=${request.cityId}&DistrictId=${request.districtId}&FiedGroupId=${request.fiedGroupId}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const GetCorporateDashboardSlice = createSlice({
  name: "corporate/dashboard",
  initialState: initialGetCorporateDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCorporateDashboardService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(GetCorporateDashboardService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(GetCorporateDashboardService.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
