import {
  Button,
  Flex,
  Grid,
  Group,
  Paper,
  TextInput,
  Text,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../connector/ModuleHook";
import { RootState } from "../connector/ModuleStore";
import { useWindowSize } from "@uidotdev/usehooks";
import CustomLoadingOverlay from "../../utils/CustomLoadingOverlay";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import {
  IconSearch,
  IconSortDescending,
  IconPlus,
  IconClearAll,
} from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "@mantine/hooks";
import { useNavigate } from "react-router";
import { MyFieldService } from "../../myfields/interactor/MyFieldInteractor";
import { EmployeeService } from "../../users/interactor/EmployeeInteractor";
import { Employee } from "../../users/data/response/EmployeeResponse";
import { GetFieldGroupNameDto } from "../data/dto/GetFieldGroupNameDto";

const PAGE_SIZE = 10;
const MainWrapper = lazy(
  () => import("../../../app/common/presentation/view/component/mainWrapper")
);

interface ProducersProps {}

const Producers: React.FC<ProducersProps> = ({}) => {
  const [fetching, setFetching] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const size = useWindowSize();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editId, setEditId] = useState("");
  const [name, setName] = useState("");
  const [nameBlured, setNameBlured] = useState(false);
  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "fieldExits",
    direction: "desc",
  });

  const [firstNameQuery, setFirstNameQuery] = useState("");
  const [debouncedFirstNameQuery] = useDebouncedValue(firstNameQuery, 200);

  const [lastNameQuery, setLastNameQuery] = useState("");
  const [debouncedLastNameQuery] = useDebouncedValue(lastNameQuery, 200);

  const [phoneNumberQuery, setPhoneNumberQuery] = useState("");
  const [debouncedPhoneNumberQuery] = useDebouncedValue(phoneNumberQuery, 200);

  const [fieldGroupsQuery, setFieldGroupsQuery] = useState("");
  const [debouncedFieldGroupsQuery] = useDebouncedValue(fieldGroupsQuery, 200);

  const [plantQuery, setPlantQuery] = useState("");
  const [debouncedPlantQuery] = useDebouncedValue(plantQuery, 200);

  const employeeService = useSelector(
    (state: RootState) => state.EmployeeState.value
  );

  const employeeData = employeeService?.data?.employees || [];

  const myFieldService = useSelector(
    (state: RootState) => state.MyFieldState.value
  );
  const myFieldData = myFieldService?.data?.fields;

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  useEffect(() => {
    dispatch(MyFieldService());
    dispatch(EmployeeService(true));
  }, []);

  const [triggerSearch, setTriggerSearch] = useState("");
  const handleSearch = () => {
    setTriggerSearch(Math.random().toString(36).substring(7));
  };

  useEffect(() => {
    if (employeeData && myFieldData) {
      const forms = employeeData.map((employee: Employee) => {
        let fieldGroupStatus = getFieldGroupName(
          myFieldData,
          employee.phoneNumber.phoneNumber
        );
        return {
          id: employee.id,
          firstName: employee.firstName,
          lastName: employee.lastName,
          phoneNumber: `${employee.phoneNumber.phoneNumber}`,
          fieldGroups: fieldGroupStatus.groupNames,
          fieldExits: fieldGroupStatus.fieldExits,
          plant: getPlantName(myFieldData, employee.phoneNumber.phoneNumber),
        } as any;
      });
      setInitialRecords(forms);
    }
  }, [employeeData, myFieldData]);

  const getFieldGroupName = (
    data: any,
    phoneNumber?: string
  ): GetFieldGroupNameDto => {
    let fieldGroupNames = [] as any[];
    let fieldExits = false;
    data.forEach((item: any) => {
      if (
        item.farmerPhoneNumber &&
        phoneNumber &&
        data.fieldGroupNames !== null &&
        item.farmerPhoneNumber.indexOf(phoneNumber) > -1
      ) {
        fieldExits = true;
        item.fieldGroupNames.forEach((fieldGroup: any) => {
          if (
            !fieldGroupNames.some(
              (exitsFieldGroup: any) => exitsFieldGroup.id === fieldGroup.id
            )
          ) {
            fieldGroupNames.push(fieldGroup);
          }
        });
      }
    });

    return {
      groupNames: fieldGroupNames.map((item: any) => item.groupName).join(", "),
      fieldExits: fieldExits,
    };
  };
  const getPlantName = (data: any, phoneNumber?: string) => {
    let plantNames = [] as any[];

    data.forEach((item: any) => {
      if (
        item.farmerPhoneNumber &&
        phoneNumber &&
        data.fieldGroupNames !== null &&
        item.farmerPhoneNumber.indexOf(phoneNumber) > -1
      ) {
        if (item.seasons) {
          item.seasons?.forEach((season: any) => {
            if (
              season.plantName &&
              plantNames.indexOf(season.plantName) === -1 &&
              season.seasonClosed != true
            ) {
              plantNames.push(season.plantName);
            }
          });
        }
      }
    });

    return plantNames.join(", ");
  };
  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({ firstName, lastName, phoneNumber, fieldGroups, plant }) => {
          console.log("debouncedFieldGroupsQuery", debouncedFieldGroupsQuery);
          if (
            debouncedFirstNameQuery !== "" &&
            firstNameQuery &&
            !`${firstName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFirstNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedLastNameQuery !== "" &&
            lastNameQuery &&
            !`${lastName}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedLastNameQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedPhoneNumberQuery !== "" &&
            phoneNumberQuery &&
            !`${phoneNumber}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedPhoneNumberQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedFieldGroupsQuery !== "" &&
            fieldGroupsQuery &&
            !`${fieldGroups}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedFieldGroupsQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedPlantQuery &&
            plantQuery &&
            !`${plant}`
              .toLowerCase()
              .replaceAll("i̇", "i")
              .includes(debouncedPlantQuery.trim().toLowerCase())
          ) {
            return false;
          }
          return true;
        }
      );

      console.log("filteredData", filteredData);
      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "firstName") {
        sortedData.sort((a, b) => {
          const valueA = a.firstName ? a.firstName : "";
          const valueB = b.firstName ? b.firstName : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "lastName") {
        sortedData.sort((a, b) => {
          const valueA = a.lastName ? a.lastName : "";
          const valueB = b.lastName ? b.lastName : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "phoneNumber") {
        sortedData.sort((a, b) => {
          const valueA = a.phoneNumber ? a.phoneNumber : "";
          const valueB = b.phoneNumber ? b.phoneNumber : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "fieldGroups") {
        sortedData.sort((a, b) => {
          const valueA = a.fieldGroups ? a.fieldGroups : "";
          const valueB = b.fieldGroups ? b.fieldGroups : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "plant") {
        sortedData.sort((a, b) => {
          const valueA = a.plant ? a.plant.toLowerCase() : "";
          const valueB = b.plant ? b.plant.toLowerCase() : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus, triggerSearch]);

  const handleClearFilters = () => {
    setFirstNameQuery("");
    setLastNameQuery("");
    setPhoneNumberQuery("");
    setFieldGroupsQuery("");
    setPlantQuery("");
    setSortStatus({
      columnAccessor: "fieldExits",
      direction: "desc",
    });
    setTimeout(() => {
      setTriggerSearch(Math.random().toString(36).substring(7));
    }, 250);
  };

  const columns = [
    {
      accessor: "firstName",
      title: "Adı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Adı"
          description="Adı ile ara."
          placeholder="Adı ara..."
          icon={<IconSearch size={16} />}
          value={firstNameQuery}
          onChange={(e) => setFirstNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: firstNameQuery !== "",
    },
    {
      accessor: "lastName",
      title: "Soyadı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Soyadı"
          description="Soyadı ile ara."
          placeholder="Soyadı ara..."
          icon={<IconSearch size={16} />}
          value={lastNameQuery}
          onChange={(e) => setLastNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: lastNameQuery !== "",
    },
    {
      accessor: "phoneNumber",
      title: "Telefon Numarası",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Telefon Numarası"
          description="Telefon numarası ile ara."
          placeholder="Telefon numarası ara..."
          icon={<IconSearch size={16} />}
          value={phoneNumberQuery}
          onChange={(e) => setPhoneNumberQuery(e.currentTarget.value)}
        />
      ),
      filtering: phoneNumberQuery !== "",
    },
    {
      accessor: "fieldGroups",
      title: "Gruplar",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Grup"
          description="Grup ile ara."
          placeholder="Grup ara..."
          icon={<IconSearch size={16} />}
          value={fieldGroupsQuery}
          onChange={(e) => setFieldGroupsQuery(e.currentTarget.value)}
        />
      ),
      filtering: fieldGroupsQuery !== "",
    },
    {
      accessor: "plant",
      title: "Ürün Adı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Ürün adı"
          description="Belirtilen ürüm adlarını ara."
          placeholder="Ürün adı ara..."
          icon={<IconSearch size={16} />}
          value={plantQuery}
          onChange={(e) => setPlantQuery(e.currentTarget.value)}
        />
      ),
      filtering: plantQuery !== "",
    },
    {
      accessor: "isRead",
      title: "Aksiyon",
      textAlignment: "left" as DataTableColumnTextAlignment,

      render: (record: any, index: number) => (
        <Group spacing={4} position="center" noWrap>
          <>
            {record.fieldExits && (
              <Button
                variant="outline"
                color="#000000"
                fullWidth
                style={{
                  color: "#000000",
                  borderColor: "#000000",
                  borderRadius: "2rem",
                  width: "100%",
                  fontWeight: 300,
                }}
                onClick={() => {
                  navigate(`/myFields?p=${record.phoneNumber}`);
                }}
              >
                Tarlalar
              </Button>
            )}
          </>
        </Group>
      ),
    },
  ];

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Üretici Listesi", href: "#" }]}
        title="Üretici Listesi"
      >
        <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
          <Suspense fallback={<CustomLoadingOverlay />}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <Grid>
                <Grid.Col lg={4} md={4}>
                  {" "}
                  <Text
                    style={{
                      fontSize: "16px",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    Toplam Üretici Sayısı: {initialRecords.length}
                  </Text>
                </Grid.Col>
                <Grid.Col lg={8} md={8}>
                  <Flex justify={"flex-end"}>
                    <Button
                      onClick={handleSearch}
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      <IconSearch height={15} /> Filtreleri Uygula
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={handleClearFilters}
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      <IconClearAll /> Filtreleri Temizle
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <DataTable
                    withColumnBorders
                    highlightOnHover
                    minHeight={250}
                    noRecordsText="Kayıt Bulunamadı..."
                    className="datatable"
                    records={pageData}
                    columns={columns}
                    totalRecords={sortedData.length}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    fetching={fetching}
                    loaderColor="#0097c4"
                    sortStatus={sortStatus}
                    onSortStatusChange={handleSortStatusChange}
                    sortIcons={{
                      sorted: <IconSortDescending size={14} />,
                      unsorted: <IconSortDescending size={14} />,
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
          </Suspense>
        </Paper>
      </MainWrapper>
    </Suspense>
  );
};

export default Producers;
