import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosHelper from "../../common/util/AxiosHelper";

import { ServiceState } from "../../common/data/ServiceState";

import { BaseResponse } from "../../common/data/BaseResponse";
import { GetFieldGroupQueryResponse } from "../data/response/GetFieldGroupQueryResponse";
import { CreateFieldGroupRequest } from "../data/request/CreateFieldGroupRequest";
import { CreateFieldGroupCommandResponse } from "../data/response/CreateFieldGroupResponse";
import { UpdateFieldGroupCommandResponse } from "../data/response/UpdateFieldGroupResponse";
import { UpdateFieldGroupRequest } from "../data/request/UpdateFieldGroupRequest";
import { DeleteFieldGroupCommandResponse } from "../data/response/DeleteFieldGroupResponse";
import { DeleteFieldGroupRequest } from "../data/request/DeleteFieldGroupRequest";
import { AssignFieldGroupFieldResponse } from "../data/response/AssignFieldGroupFieldResponse";
import { AssignFieldGroupFieldRequest } from "../data/request/AssignFieldGroupFieldRequest";

const initialGetFieldGroupState: ServiceState<GetFieldGroupQueryResponse> = {};

// Async Thunk action creator
export const GetFieldGroupService = createAsyncThunk(
  "fields/fieldGroup",
  async () => {
    try {
      const response =
        await AxiosHelper.getInstance().get<GetFieldGroupQueryResponse>(
          `fields/fieldGroup`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const GetFieldGroupSlice = createSlice({
  name: "fields/fieldGroup",
  initialState: initialGetFieldGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetFieldGroupService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(GetFieldGroupService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(GetFieldGroupService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialCreateFieldGroupState: ServiceState<CreateFieldGroupCommandResponse> =
  {};

export const CreateFieldGroupService = createAsyncThunk(
  "fields/addfieldGroup",
  async (request: CreateFieldGroupRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<CreateFieldGroupCommandResponse>(
          `fields/fieldGroup`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const CreateFieldGroupSlice = createSlice({
  name: "fields/addfieldGroup",
  initialState: initialCreateFieldGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CreateFieldGroupService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(CreateFieldGroupService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(CreateFieldGroupService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialUpdateFieldGroupState: ServiceState<UpdateFieldGroupCommandResponse> =
  {};

export const UpdateFieldGroupService = createAsyncThunk(
  "fields/addfieldGroup",
  async (request: UpdateFieldGroupRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().put<UpdateFieldGroupCommandResponse>(
          `fields/fieldGroup`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const UpdateFieldGroupSlice = createSlice({
  name: "fields/addfieldGroup",
  initialState: initialUpdateFieldGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UpdateFieldGroupService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(UpdateFieldGroupService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(UpdateFieldGroupService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialDeleteFieldGroupState: ServiceState<DeleteFieldGroupCommandResponse> =
  {};

export const DeleteFieldGroupService = createAsyncThunk(
  "fields/addfieldGroup",
  async (request: DeleteFieldGroupRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().delete<DeleteFieldGroupCommandResponse>(
          `fields/fieldGroup?Id=${request.id}`
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const DeleteFieldGroupSlice = createSlice({
  name: "fields/addfieldGroup",
  initialState: initialDeleteFieldGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DeleteFieldGroupService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(DeleteFieldGroupService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(DeleteFieldGroupService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});

const initialAssignFieldGroupFieldState: ServiceState<AssignFieldGroupFieldResponse> =
  {};

export const AssignFieldGroupFieldService = createAsyncThunk(
  "fields/assignGroup",
  async (request: AssignFieldGroupFieldRequest) => {
    try {
      const response =
        await AxiosHelper.getInstance().post<AssignFieldGroupFieldResponse>(
          `fields/assignGroup`,
          request
        );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const AssignFieldGroupFieldSlice = createSlice({
  name: "fields/assignGroup",
  initialState: initialAssignFieldGroupFieldState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AssignFieldGroupFieldService.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(AssignFieldGroupFieldService.fulfilled, (state, action) => {
        state.isLoading = false;
        state.value = action.payload;
      })
      .addCase(AssignFieldGroupFieldService.rejected, (state, action) => {
        state.isLoading = false;
        // TODO : error
      });
  },
});
