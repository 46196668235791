// eslint-disable
import {
  Box,
  Button,
  Col,
  Flex,
  Grid,
  Input,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Overlay,
  Paper,
  Select,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import { DateInput, DatesProvider } from "@mantine/dates";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { CalendarBlank } from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../../../modules/myfields/connector/ModuleHook";
import { RootState } from "../../../../../../modules/myfields/connector/ModuleStore";
import { AddFieldRequests } from "../../../../../../modules/myfields/data/request/AddFieldRequests";
import { CultivationRequest } from "../../../../../../modules/myfields/data/request/CultivationRequest";
import { DistrictRequest } from "../../../../../../modules/myfields/data/request/DistrictRequest";
import { NeighborhoodRequest } from "../../../../../../modules/myfields/data/request/NeighborhoodRequest";
import { PlantRequest } from "../../../../../../modules/myfields/data/request/PlantRequest";
import { PlantVariantRequest } from "../../../../../../modules/myfields/data/request/PlantVariantRequest";
import { AddFieldResponse } from "../../../../../../modules/myfields/data/response/AddFieldResponse";
import { AddFieldService } from "../../../../../../modules/myfields/interactor/AddFieldInteractor";
import { CityService } from "../../../../../../modules/myfields/interactor/CityInteractor";
import { CultivationService } from "../../../../../../modules/myfields/interactor/CultivationInteractor";
import { DistrictService } from "../../../../../../modules/myfields/interactor/DistrictInteractor";
import { NeighborhoodService } from "../../../../../../modules/myfields/interactor/NeighborhoodInteractor";
import { PlantService } from "../../../../../../modules/myfields/interactor/PlantInteractor";
import { PlantVariantService } from "../../../../../../modules/myfields/interactor/PlantVariantInteractor";
import { EmployeeService } from "../../../../../../modules/users/interactor/EmployeeInteractor";
import { format, setDate } from "date-fns";
import { tr } from "date-fns/locale";

import { useNavigate } from "react-router";
import {
  Plant,
  PlantsResponse,
} from "../../../../../../modules/myfields/data/response/PlantsResponse";
import { GetFieldGroupService } from "../../../../../../modules/fieldgroups/interactor/FieldGroupInteractor";
import { FieldGroupDto } from "../../../../../../modules/fieldgroups/data/dto/FieldGroupDto";

const AddFieldsCard: React.FC = () => {
  const [fieldName, setFieldName] = useState("");
  const [farmerPhoneNumber, setFarmerPhoneNumber] = useState("");
  const [agricultureEngineerId, setAgricultureEngineerId] = useState<string[]>(
    []
  );
  const [agricultureEngineerName, setAgricultureEngineerName] = useState<
    string[]
  >([]);
  const [block, setBlock] = useState("");
  const [city, setCity] = useState<string | null>(null);
  const [district, setDistrict] = useState<string | null>(null);
  const [neighborhood, setNeighborhood] = useState<string | null>(null);
  const [parcel, setParcel] = useState("");
  const [plant, setPlant] = useState<string | null>(null);
  const [plantType, setPlantType] = useState<string | null>(null);
  const [cultivationArea, setCultivationArea] = useState<string | null>(null);
  const [cultivationAreaName, setCultivationAreaName] = useState("");
  const [dateToHarvest, setDateToHarvest] = useState<Date | null>(null);
  const [dateToPlant, setDateToPlant] = useState<Date | null>(null);
  const [isVisibleNeighborhood, setIsVisibleNeighborhood] = useState(false);
  const [isVisibleDistrict, setIsVisibleDistrict] = useState(false);
  const [cultivationAreaId, setCultivationAreaId] = useState<number | null>(
    null
  );
  const [isPlantVisible, setIsPlantVisible] = useState(false);
  const [isPlantTypeVisible, setIsPlantTypeVisible] = useState(false);
  const [plantName, setPlantName] = useState("");
  const [plantTypeName, setPlantTypeName] = useState("");
  const [userId, setUserId] = useState<string>("");
  const [farmerName, setFarmerName] = useState<string>("");
  const [farmerSurname, setFarmerSurname] = useState<string>("");
  const [dateToPlantForDateToHarvest, setDateToPlantForDateToHarvest] =
    useState<Date | null>(null);
  const [plantData, setPlantData] = useState<Plant[]>();
  const [fieldGroups, setFieldGroups] = useState<FieldGroupDto[]>([]);

  const dispatch = useAppDispatch();

  const managerService = useSelector(
    (state: RootState) => state.managerState.value
  );

  const managerData =
    managerService?.data?.employees.filter(
      (employee) => employee.authorityType === 2
    ) || [];

  useEffect(() => {
    if (managerService == null) {
      dispatch(EmployeeService());
    }
    dispatch(GetFieldGroupService());
  }, []);

  const getFieldGroupService = useSelector(
    (state: RootState) => state.GetFieldGroupState.value
  );

  const getFieldGroupData = getFieldGroupService?.data?.fieldGroup;

  const cityService = useSelector((state: RootState) => state.cityState.value);

  const cultivationService = useSelector(
    (state: RootState) => state.cultivationState.value
  );

  const key = cityService?.data?.cities.map((city) => city.id) || [];
  const cityData = cityService?.data?.cities;

  const cultivationData = cultivationService?.data?.cultivationAreas;

  const districtService = useSelector(
    (state: RootState) => state.districtState.value
  );
  const districtData = districtService?.data?.districts;

  const neighborhoodService = useSelector(
    (state: RootState) => state.neigborhoodState.value
  );
  const neighborhoodData = neighborhoodService?.data?.neighbourhoods;

  const plantService = useSelector(
    (state: RootState) => state.plantState.value
  );
  //const plantData = plantService?.data?.plants;

  const plantTypeService = useSelector(
    (state: RootState) => state.plantTypeState.value
  );
  const plantTypeData = plantTypeService?.data?.plantVariants;

  const [submitted, setSubmitted] = useState(false);

  const [fetching, setFetching] = useState(false);

  const navigate = useNavigate();

  const formatPhoneNumber = (value: string) => {
    const inputVal = value.replace(/\D/g, "");

    if (inputVal.length === 0 || value === "() ") {
      return "";
    } else if (inputVal.length <= 10) {
      return `(${inputVal.slice(0, 3)}) ${inputVal.slice(
        3,
        6
      )} ${inputVal.slice(6, 10)}`;
    } else {
      return `(${inputVal.slice(0, 3)}) ${inputVal.slice(
        3,
        6
      )} ${inputVal.slice(6, 10)}`;
    }
  };

  const handleSave = () => {
    setSubmitted(true);
    if (
      !fieldName ||
      !city ||
      !district ||
      !neighborhood ||
      !cultivationArea ||
      !parcel ||
      !block ||
      !plant ||
      !plantType ||
      !farmerPhoneNumber ||
      farmerPhoneNumber.length < 14
    ) {
      setFetching(false);
      toast.error("Lütfen Gerekli Alanları Doldurunuz");
      return;
    }
    const addFieldRequest: AddFieldRequests = {
      fieldName: fieldName,
      cityId: city || "",
      districtId: district || "",
      neighbourhoodId: neighborhood || "",
      parcel: parseInt(parcel || "0"),
      block: parseInt(block || "0"),
      latitude: 0,
      longitude: 0,
      area: null,

      season: {
        seasonName: `Sezon ${format(new Date(), "MMMM", {
          locale: tr,
        })} ${format(new Date(), "yyyy")}`,
        startDate: new Date().toISOString(),
        cultivationAreaId: cultivationArea || "",
        cultivationAreaName: cultivationAreaName || "",
        plantId: plant || "",
        plantName: plantName || "",
        plantVariantId: plantType || "",
        plantVariantName: plantType || "",
        plantingDate: dateToPlant || undefined,
        estimatedHarvestDate: dateToHarvest || undefined,
        harvestDate: dateToHarvest || undefined,
        seasonNotes: [],
        seasonClosed: false,
      },
      farmerPhoneNumber: {
        countryCode: "+90",
        phoneNumber: farmerPhoneNumber.replace(/\D/g, ""),
      },
      farmerFirstName: farmerName,
      farmerLastName: farmerSurname,

      managers: agricultureEngineerId.map((id) => {
        return {
          employeeId: id,
          userId: "",
          employeeName:
            (managerData.find((manager) => manager.id === id)?.firstName ||
              "") +
            " " +
            (managerData.find((manager) => manager.id === id)?.lastName || ""),
        };
      }),
      fieldGroups: fieldGroups,
    };
    setFetching(true);
    dispatch(AddFieldService(addFieldRequest)).then((response) => {
      if (response !== undefined) {
        let responsePayload = response.payload as AddFieldResponse | undefined;
        if (responsePayload?.success) {
          setFieldName("");
          setCity(null);
          setDistrict(null);
          setNeighborhood(null);
          setParcel("");
          setBlock("");
          setPlant(null);
          setPlantType(null);
          setDateToPlant(null);
          setDateToHarvest(null);
          setFarmerPhoneNumber("");
          setAgricultureEngineerId([]);
          setAgricultureEngineerName([]);
          setCultivationArea(null);
          setFieldGroups([]);
          MultiSelect.defaultProps = {
            value: [],
          };
          form.reset();
          toast.success("Tarla Eklendi");
          setFetching(false);
          navigate("/myfields");
        } else {
          toast.error(responsePayload?.userMessage);
          setFetching(false);
          //navigate("/myfields");
        }
      }
    });
  };

  const handleCity = () => {
    setDistrict(null);
    setNeighborhood(null);
    setIsVisibleDistrict(false);
    setIsVisibleNeighborhood(false);

    dispatch(CityService());
  };

  const handleDistrict = () => {
    if (city != null) {
      setDistrict(null);
      setNeighborhood(null);
      setIsVisibleDistrict(false);
      setIsVisibleNeighborhood(false);

      dispatch(DistrictService(new DistrictRequest(city, "")));
    }
  };

  const handleNeighborhood = () => {
    if (district != null) {
      setNeighborhood(null);
      setIsVisibleNeighborhood(false);
      dispatch(NeighborhoodService(new NeighborhoodRequest(district, "")));
    }
  };

  useEffect(() => {
    if (plantService) {
      setPlantData(plantService?.data?.plants);
    }
  }, [plantService]);

  useEffect(() => {
    handleCity();
  }, []);

  useEffect(() => {
    if (city != null) {
      handleDistrict();
    }
  }, [city]);

  useEffect(() => {
    if (districtData != null) {
      setIsVisibleDistrict(true);
    }
  }, [districtData]);

  useEffect(() => {
    if (districtData != null) {
      handleNeighborhood();
    }
  }, [districtData]);

  useEffect(() => {
    if (neighborhoodData != null) {
      setIsVisibleNeighborhood(true);
    }
  }, [neighborhoodData]);

  const handleCultivation = () => {
    setPlant(null);
    setPlantType(null);
    setIsPlantTypeVisible(false);
    setIsPlantVisible(false);
    dispatch(CultivationService(new CultivationRequest()));
  };

  const handlePlantName = (value: string | null) => {
    if (value != null) {
      const selectedPlant = plantData?.find((plant) => plant.id === value);
      if (selectedPlant) {
        setPlantName(selectedPlant.name);
      }
    }
  };

  const handlePlants = () => {
    setPlant(null);
    setPlantType(null);
    setIsPlantVisible(false);
    setIsPlantTypeVisible(false);
    if (cultivationArea != null) {
      const selectedCultivation = cultivationData?.find(
        (cultivation) => cultivation.id === cultivationArea
      );

      if (selectedCultivation) {
        setCultivationAreaId(selectedCultivation.areaId);
        if (selectedCultivation.areaId != null && plant == null) {
          dispatch(PlantService(new PlantRequest(selectedCultivation.areaId)));
        }
      }
    }
  };

  const handlePlantTypeName = (value: string | null) => {
    if (plantType != null) {
      const selectedPlantType = plantTypeData?.find(
        (plantVariant) => plantVariant.id === plantType
      );
      if (selectedPlantType) {
        setPlantTypeName(selectedPlantType.name);
      }
    }
  };

  const handlePlantVariants = () => {
    setPlantType(null);
    setIsPlantTypeVisible(false);
    if (plant != null) {
      dispatch(PlantVariantService(new PlantVariantRequest(plant)));
    }
  };

  const handleCultivationAreaName = (value: string | null) => {
    if (value != null) {
      const selectedCultivation = cultivationData?.find(
        (cultivation) => cultivation.id === value
      );
      if (selectedCultivation) {
        setCultivationAreaName(selectedCultivation.name);
      }
    }
  };

  useEffect(() => {
    handleCultivation();
  }, []);

  useEffect(() => {
    if (cultivationData != null) {
      handlePlants();
    }
  }, [cultivationData]);

  useEffect(() => {
    if (plantData != null) {
      setIsPlantVisible(true);
    }
  }, [plantData]);

  useEffect(() => {
    if (plantData != null) {
      handlePlantVariants();
    }
  }, [plantData]);

  useEffect(() => {
    if (plantTypeData != null) {
      setIsPlantTypeVisible(true);
    }
  }, [plantTypeData]);

  const form = useForm({
    initialValues: {
      fieldName: "",
      city: "",
      farmer: "",
      province: "",
      agricultureEngineer: "",
      street: "",
      place: "",
      block: "",
      product: "",
      parcel: "",
      productType: "",
      dateToPlant: undefined,
      dateToHarvest: undefined,
      farmerPhoneNumber: "",
      fieldGroups: [] as FieldGroupDto[],
    },
    validate: {
      farmerPhoneNumber: (value) => {
        if (value === null) {
          return "Telefon numarası boş olamaz"; // Empty value is invalid
        }

        const numericValue = value.replace(/\D/g, "");

        if (/^\d{10}$/.test(numericValue)) {
          return null; // Valid
        } else {
          return "Geçersiz telefon numarası";
        }
      },
      fieldName: (value) => {
        if (!value) return "Tarla adı boş olamaz";
        if (value.length > 50) return "Tarla adı en fazla 50 karakter olabilir";
        return null;
      },
      city: (value) => (value ? null : "İl seçilmelidir"),
      province: (value) => (value ? null : "İlçe seçilmelidir"),
      street: (value) => (value ? null : "Mahalle seçilmelidir"),
      place: (value) => (value ? null : "Yetiştirilme alanı seçilmelidir"),
      product: (value) => (value ? null : "Ürün seçilmelidir"),
      block: (value, allValues) => {
        if (!value) return "Ada boş olamaz";
        if (isNaN(Number(value))) return "Ada bir sayı olmalıdır";
        if (value === "0" && allValues.parcel === "0")
          return "Ada ve parsel aynı anda 0 olamaz";
        return null;
      },
      parcel: (value, allValues) => {
        if (!value) return "Parsel boş olamaz";
        if (isNaN(Number(value))) return "Parsel bir sayı olmalıdır";
        if (value === "0" && allValues.block === "0")
          return "Ada ve parsel aynı anda 0 olamaz";
        return null;
      },

      productType: (value) => (value ? null : "Ürün tipi seçilmelidir"),
      agricultureEngineer: (value) =>
        value ? null : "Ziraat mühendisi seçilmelidir",
    },
  });
  const fieldNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const farmerNameRef = useRef<HTMLInputElement>(null);
  const farmerSurnameRef = useRef<HTMLInputElement>(null);
  const agricultureEngineerMultiSelectRef = useRef<any>(null);
  const placeRef = useRef<HTMLInputElement>(null);
  const productRef = useRef<HTMLInputElement>(null);
  const productTypeRef = useRef<HTMLInputElement>(null);
  const plantDateRef = useRef<HTMLInputElement>(null);
  const harvestDateRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const provinceRef = useRef<HTMLInputElement>(null);
  const neighborhoodRef = useRef<HTMLInputElement>(null);
  const adaRef = useRef<HTMLInputElement>(null);
  const parcelRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fieldNameRef.current) {
      fieldNameRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          phoneRef.current?.focus();
        }
      });
    }
    if (phoneRef.current) {
      phoneRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          farmerNameRef.current?.focus();
        }
      });
    }
    if (farmerNameRef.current) {
      farmerNameRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          farmerSurnameRef.current?.focus();
        }
      });
    }
    if (farmerSurnameRef.current) {
      farmerSurnameRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          agricultureEngineerMultiSelectRef.current?.focus();
        }
      });
    }
    if (agricultureEngineerMultiSelectRef.current) {
      agricultureEngineerMultiSelectRef.current.addEventListener(
        "keydown",
        (e: any) => {
          if (e.key === "Tab" && !e.shiftKey) {
            e.preventDefault();
            placeRef.current?.focus();
          }
        }
      );
    }
    if (placeRef.current) {
      placeRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          productRef.current?.focus();
        }
      });
    }
    if (productRef.current) {
      productRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          productTypeRef.current?.focus();
        }
      });
    }
    if (productTypeRef.current) {
      productTypeRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          plantDateRef.current?.focus();
        }
      });
    }
    if (plantDateRef.current) {
      plantDateRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          harvestDateRef.current?.focus();
        }
      });
    }
    if (harvestDateRef.current) {
      harvestDateRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          cityRef.current?.focus();
        }
      });
    }
    if (cityRef.current) {
      cityRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          provinceRef.current?.focus();
        }
      });
    }
    if (provinceRef.current) {
      provinceRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          neighborhoodRef.current?.focus();
        }
      });
    }
    if (neighborhoodRef.current) {
      neighborhoodRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          adaRef.current?.focus();
        }
      });
    }
    if (adaRef.current) {
      adaRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
          parcelRef.current?.focus();
        }
      });
    }
    if (parcelRef.current) {
      parcelRef.current.addEventListener("keydown", (e) => {
        if (e.key === "Tab" && !e.shiftKey) {
          e.preventDefault();
        }
      });
    }
  }, [
    fieldNameRef,
    phoneRef,
    farmerNameRef,
    farmerSurnameRef,
    agricultureEngineerMultiSelectRef,
    placeRef,
    productRef,
    productTypeRef,
    plantDateRef,
    harvestDateRef,
    cityRef,
    provinceRef,
    neighborhoodRef,
    adaRef,
    parcelRef,
  ]);

  return (
    <Paper p={20} className="widgets" radius={rem(12)} bg="white">
      <Space h={"30px"} />

      <LoadingOverlay
        visible={fetching}
        overlayBlur={2}
        loaderProps={{ size: "md", color: "#0097c4", variant: "oval" }}
        pos={"absolute"}
      />

      <form onSubmit={form.onSubmit((values) => {})}>
        <Grid>
          <Grid.Col sm={12} md={6}>
            <Col span={12}>
              <Text
                style={{
                  fontStyle: "bold",
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Tarla Adı
              </Text>
              <TextInput
                tabIndex={1}
                ref={fieldNameRef}
                placeholder="Tarla Adını Girin"
                {...form.getInputProps("fieldName")}
                onChange={(event) => (
                  form.getInputProps("fieldName").onChange(event.target.value),
                  setFieldName(event.target.value)
                )}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Grid>
                <Grid.Col span={12}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Üretici Tel No
                  </Text>
                  <TextInput
                    tabIndex={2}
                    ref={phoneRef}
                    {...form.getInputProps("farmerPhoneNumber")}
                    placeholder="Üretici Tel No Girin"
                    value={farmerPhoneNumber}
                    onKeyDown={(event) => {
                      // Check if the pressed key is delete key
                      if (event.key === "Backspace") {
                        event.preventDefault(); // Prevent the default behavior of delete key

                        // Check if the entire text is selected
                        const input = event.target as HTMLInputElement;
                        if (
                          input.selectionStart === 0 &&
                          input.selectionEnd === farmerPhoneNumber.length
                        ) {
                          // If the entire text is selected, delete all characters
                          form.setFieldValue("farmerPhoneNumber", "");
                          setFarmerPhoneNumber("");
                        } else {
                          // If not, delete the last character
                          const newValue = farmerPhoneNumber.slice(0, -1);
                          form.setFieldValue("farmerPhoneNumber", newValue);
                          setFarmerPhoneNumber(newValue);
                        }
                      }
                    }}
                    onChange={(event) => {
                      let inputVal = event.currentTarget.value.replace(
                        /\D/g,
                        ""
                      );

                      if (!inputVal || inputVal[0] !== "5") {
                        inputVal = "5" + inputVal;
                      }

                      let formattedVal = "";
                      if (inputVal.length <= 3) {
                        formattedVal = `(${inputVal}`;
                      } else if (inputVal.length <= 6) {
                        formattedVal = `(${inputVal.slice(
                          0,
                          3
                        )}) ${inputVal.slice(3)}`;
                      } else {
                        formattedVal = `(${inputVal.slice(
                          0,
                          3
                        )}) ${inputVal.slice(3, 6)} ${inputVal.slice(6, 10)}`;
                      }

                      form
                        .getInputProps("farmerPhoneNumber")
                        .onChange(event.target.value);
                      form.setFieldValue("farmerPhoneNumber", formattedVal);
                      setFarmerPhoneNumber(formattedVal);
                    }}
                    sx={{
                      Input: {
                        height: "48px",
                        borderRadius: "18px",
                      },
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Üretici Adı
                  </Text>
                  <TextInput
                    ref={farmerNameRef}
                    tabIndex={3}
                    maxLength={15}
                    {...form.getInputProps("farmerName")}
                    placeholder="Üretici Adı Girin"
                    value={farmerName}
                    onChange={(event) => (
                      form
                        .getInputProps("farmerName")
                        .onChange(event.target.value),
                      setFarmerName(event.target.value)
                    )}
                    sx={{
                      Input: {
                        height: "48px",
                        borderRadius: "18px",
                      },
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Medium",
                      color: "#136583",
                    }}
                  >
                    {" "}
                    Üretici Soyadı
                  </Text>
                  <TextInput
                    ref={farmerSurnameRef}
                    tabIndex={4}
                    maxLength={10}
                    {...form.getInputProps("farmerSurname")}
                    placeholder="Üretici Soyadı Girin"
                    value={farmerSurname}
                    onChange={(event) => (
                      form
                        .getInputProps("farmerSurname")
                        .onChange(event.target.value),
                      setFarmerSurname(event.target.value)
                    )}
                    sx={{
                      Input: {
                        height: "48px",
                        borderRadius: "18px",
                      },
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Ziraat Mühendisi
              </Text>
              <MultiSelect
                tabIndex={5}
                placeholder="Ziraat Mühendisi Seçin"
                className="multi-select-add-user"
                data={managerData.map((manager) => ({
                  value: manager.id,
                  label: manager.firstName + " " + manager.lastName,
                }))}
                searchable
                nothingFound="Bulunamadı"
                clearable
                ref={agricultureEngineerMultiSelectRef}
                value={agricultureEngineerId}
                onChange={(value) => {
                  setAgricultureEngineerId(value);
                  setAgricultureEngineerName(
                    value.map((id) => {
                      const selectedManager = managerData.find(
                        (manager) => manager.id === id
                      );
                      return (
                        selectedManager?.firstName ||
                        "" + " " + selectedManager?.lastName ||
                        ""
                      );
                    })
                  );
                }}
                sx={{}}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Yetiştirilme Alanı
              </Text>
              <Select
                tabIndex={6}
                ref={placeRef}
                searchable
                nothingFound="Veri Bulunamadı"
                placeholder="Yetiştirilme Alanını Seçin"
                data={
                  cultivationData?.map((cultivation) => ({
                    value: cultivation.id,
                    label: cultivation.name,
                  })) || []
                }
                {...form.getInputProps("place")}
                onChange={(value) => {
                  form.getInputProps("place").onChange(value);
                  setCultivationArea(value);
                  handleCultivationAreaName(value);
                }}
                onSelect={() => {
                  handlePlants();
                }}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Ürün
              </Text>
              <Select
                tabIndex={7}
                ref={productRef}
                placeholder="Ürün Seçin"
                searchable
                nothingFound="Veri Bulunamadı"
                data={
                  plantData?.map((plant) => ({
                    value: plant.id,
                    label: plant.name,
                  })) || []
                }
                {...form.getInputProps("product")}
                onChange={(value) => (
                  form.getInputProps("product").onChange(value),
                  handlePlantName(value),
                  setPlant(value)
                )}
                onSelect={() => {
                  handlePlantVariants();
                }}
                disabled={!isPlantVisible}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Ürün Tipi
              </Text>
              <Select
                tabIndex={8}
                ref={productTypeRef}
                placeholder="Ürün Tipini Seçin"
                searchable
                nothingFound="Veri Bulunamadı"
                data={
                  plantTypeData?.map((plantType) => ({
                    value: plantType.id,
                    label: plantType.name,
                  })) || []
                }
                {...form.getInputProps("productType")}
                onChange={(value) => (
                  form.getInputProps("productType").onChange(value),
                  handlePlantTypeName(value),
                  setPlantType(value)
                )}
                disabled={!isPlantTypeVisible}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Ekim Tarihi
              </Text>
              <DatesProvider
                settings={{
                  locale: dayjs.locale("tr"),
                  firstDayOfWeek: 1,
                  weekendDays: [0, 6],
                }}
              >
                <DateInput
                  tabIndex={9}
                  ref={plantDateRef}
                  valueFormat="DD.MM.YYYY"
                  rightSection={<CalendarBlank size={16} color="#6c757d" />}
                  clearable
                  allowDeselect
                  hideOutsideDates
                  placeholder="Ekim Dikim Tarihini Seçin"
                  {...form.getInputProps("dateToPlant")}
                  minDate={dayjs().subtract(100, "year").toDate()}
                  maxDate={dayjs().add(1, "year").toDate()}
                  onChange={(value) => {
                    const dateValue = value ? new Date(value) : new Date();
                    const minDate = dayjs().subtract(100, "year").toDate();
                    const maxDate = dayjs().add(1, "year").toDate();

                    if (dateValue) {
                      if (dateValue >= minDate && dateValue <= maxDate) {
                        setDateToPlant(value);
                        setDateToPlantForDateToHarvest(value);
                        form.getInputProps("dateToPlant").onChange(value);
                      }
                    }
                  }}
                  sx={{
                    Input: {
                      height: "48px",
                      borderRadius: "18px",
                    },
                  }}
                />
              </DatesProvider>
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Hasat Tarihi
              </Text>
              <DatesProvider
                settings={{
                  locale: dayjs.locale("tr"),
                  firstDayOfWeek: 1,
                  weekendDays: [0, 6],
                }}
              >
                <DateInput
                  tabIndex={10}
                  ref={harvestDateRef}
                  rightSection={<CalendarBlank size={16} color="#6c757d" />}
                  valueFormat="DD.MM.YYYY"
                  hideOutsideDates
                  clearable
                  allowDeselect
                  placeholder="Hasat Tarihini Seçin"
                  {...form.getInputProps("dateToHarvest")}
                  date={
                    dateToPlantForDateToHarvest
                      ? new Date(dateToPlantForDateToHarvest) < new Date()
                        ? new Date()
                        : new Date(dateToPlantForDateToHarvest)
                      : undefined
                  }
                  onDateChange={setDateToPlantForDateToHarvest}
                  minDate={
                    dayjs(dateToPlant).diff(dayjs(), "day") >= 0
                      ? new Date(dateToPlant ? dateToPlant : "")
                      : dayjs().toDate()
                  }
                  maxDate={
                    dayjs(dateToPlant).isAfter(dayjs())
                      ? dayjs(dateToPlant).add(1, "year").toDate()
                      : dayjs().add(1, "year").toDate()
                  }
                  onChange={(value) => {
                    const dateValue = value ? new Date(value) : null;
                    const minDate =
                      dayjs(dateToPlant).diff(dayjs(), "day") > 0
                        ? new Date(dateToPlant ? dateToPlant : "")
                        : dayjs().toDate();
                    const maxDate = dayjs(dateToPlant).isAfter(dayjs())
                      ? dayjs(dateToPlant).add(1, "year").toDate()
                      : dayjs().add(1, "year").toDate();

                    if (dateValue) {
                      if (dateValue >= minDate && dateValue <= maxDate) {
                        setDateToHarvest(value);
                        form.getInputProps("dateToHarvest").onChange(value);
                      }
                    }
                  }}
                  sx={{
                    Input: {
                      height: "48px",
                      borderRadius: "18px",
                    },
                  }}
                />
              </DatesProvider>
            </Col>
          </Grid.Col>

          <Grid.Col sm={12} md={6}>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                İl
              </Text>
              <Select
                tabIndex={11}
                ref={cityRef}
                {...form.getInputProps("city")}
                placeholder="İl Seçin"
                searchable
                nothingFound="Veri Bulunamadı"
                data={
                  cityData?.map((city) => ({
                    value: city.id,
                    label: city.name,
                  })) || []
                }
                onChange={(value) => {
                  form.getInputProps("city").onChange(value);
                  setCity(value);
                }}
                onSelect={() => {
                  handleDistrict();
                }}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                İlçe
              </Text>
              <Select
                placeholder="İlçe Seçin"
                tabIndex={12}
                ref={provinceRef}
                searchable
                nothingFound="Veri Bulunamadı"
                data={
                  districtData?.map((district) => ({
                    value: district.id,
                    label: district.name,
                  })) || []
                }
                {...form.getInputProps("province")}
                onChange={(value) => (
                  form.getInputProps("province").onChange(value),
                  setDistrict(value)
                )}
                onSelect={() => {
                  handleNeighborhood();
                }}
                disabled={!isVisibleDistrict}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Mahalle
              </Text>
              <Select
                tabIndex={13}
                ref={neighborhoodRef}
                placeholder="Mahalle Seçin"
                searchable
                nothingFound="Veri Bulunamadı"
                data={
                  neighborhoodData?.map((neighborhood) => ({
                    value: neighborhood.id,
                    label: neighborhood.name,
                  })) || []
                }
                {...form.getInputProps("street")}
                onChange={(value) => (
                  form.getInputProps("street").onChange(value),
                  setNeighborhood(value)
                )}
                disabled={!isVisibleNeighborhood}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Ada
              </Text>
              <TextInput
                tabIndex={14}
                ref={adaRef}
                placeholder="Ada Nosu Girin"
                {...form.getInputProps("block")}
                onChange={(event) => (
                  form.getInputProps("block").onChange(event.target.value),
                  setBlock(event.target.value)
                )}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Poppins-Medium",
                  color: "#136583",
                }}
              >
                {" "}
                Parsel
              </Text>
              <TextInput
                tabIndex={15}
                ref={parcelRef}
                placeholder="Parsel Girin"
                {...form.getInputProps("parcel")}
                onChange={(event) => (
                  form.getInputProps("parcel").onChange(event.target.value),
                  setParcel(event.target.value)
                )}
                sx={{
                  Input: {
                    height: "48px",
                    borderRadius: "18px",
                  },
                }}
              />
            </Col>
            <Col span={12}>
              <Box sx={{ position: "relative" }}>
                <Text
                  style={{
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#136583",
                  }}
                >
                  {" "}
                  Grup
                </Text>
                <MultiSelect
                  {...form.getInputProps("fieldGroups")}
                  searchable
                  className="multi-select-add-user"
                  placeholder="Grup Seçin"
                  data={
                    getFieldGroupData?.map((fielGroup) => ({
                      value: fielGroup.id,
                      label: fielGroup.groupName ?? "",
                    })) || []
                  }
                  value={
                    fieldGroups?.map(
                      (fieldGroup: any) => fieldGroup.id ?? ""
                    ) || []
                  }
                  onChange={(value) => {
                    const fieldGroups = value.map((id) => {
                      const selectedFieldGroup = getFieldGroupData?.find(
                        (fielGroup) => fielGroup.id === id
                      );
                      return {
                        id: id,
                        groupName: selectedFieldGroup
                          ? selectedFieldGroup.groupName
                          : "", // Include both first name and last name
                      } as FieldGroupDto;
                    });
                    setFieldGroups(fieldGroups);
                  }}
                  sx={{}}
                />
              </Box>
            </Col>
          </Grid.Col>
        </Grid>

        <Space h={"20px"} />

        <Grid>
          <Grid.Col offsetMd={9} md={3} sm={12}>
            <Button
              style={{
                paddingBottom: "11.5px",
                paddingTop: "11.5px",
                width: "100%",
                borderRadius: rem(32),
                backgroundColor: "#0097c4",
                color: "#FFFFFF",
                fontFamily: "Poppins-Light",
                fontSize: rem(12),
                textAlign: "center",
              }}
              type="submit"
              onClick={() => {
                handleSave();
              }}
            >
              Tarla Ekle
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddFieldsCard;
