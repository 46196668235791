import {
  Button,
  Col,
  Drawer,
  Flex,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  Text,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../connector/ModuleHook";
import { RootState } from "../connector/ModuleStore";
import { useWindowSize } from "@uidotdev/usehooks";
import CustomLoadingOverlay from "../../utils/CustomLoadingOverlay";
import { Info } from "phosphor-react";
import {
  IconSettings,
  IconEdit,
  IconDetails,
  IconClearAll,
} from "@tabler/icons-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import { IconSearch, IconSortDescending, IconPlus } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { useDebouncedValue } from "@mantine/hooks";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import moment from "moment";
import { DatePicker, DatesProvider } from "@mantine/dates";
import dayjs from "dayjs";
import {
  CreateFieldGroupService,
  DeleteFieldGroupService,
  GetFieldGroupService,
  UpdateFieldGroupService,
} from "../interactor/FieldGroupInteractor";
import { FieldGroupDto } from "../data/dto/FieldGroupDto";
import { DeleteFieldGroupRequest } from "../data/request/DeleteFieldGroupRequest";

const PAGE_SIZE = 10;
const MainWrapper = lazy(
  () => import("../../../app/common/presentation/view/component/mainWrapper")
);

interface FieldGroupsProps {}

const FieldGroups: React.FC<FieldGroupsProps> = ({}) => {
  const [fetching, setFetching] = useState(true);
  const dispatch = useAppDispatch();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editId, setEditId] = useState("");
  const [name, setName] = useState("");
  const [nameBlured, setNameBlured] = useState(false);
  const [pageData, setPageData] = useState<any[]>([]);
  const [initialRecords, setInitialRecords] = useState<any[]>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "createdDate",
    direction: "desc",
  });

  const [nameQuery, setNameQuery] = useState("");
  const [debouncedNameQuery] = useDebouncedValue(nameQuery, 200);
  const [createdDateQuery, setCreatedDateQuery] = useState("");
  const [debouncedCreatedDateQuery] = useDebouncedValue(createdDateQuery, 200);
  const [openNoteAddDrawer, setOpenNoteAddDrawer] = useState(false);

  const getFieldGroupService = useSelector(
    (state: RootState) => state.GetFieldGroupState.value
  );

  const getFieldGroupData = getFieldGroupService?.data?.fieldGroup;

  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };

  useEffect(() => {
    dispatch(GetFieldGroupService());
  }, []);

  const [triggerSearch, setTriggerSearch] = useState("");
  const handleSearch = () => {
    setTriggerSearch(Math.random().toString(36).substring(7));
  };

  useEffect(() => {
    if (getFieldGroupData) {
      const forms = getFieldGroupData.map((fieldGroup: FieldGroupDto) => {
        return {
          id: fieldGroup.id,
          name: fieldGroup.groupName || "-",
          createdDate: fieldGroup.createdDate
            ? moment(fieldGroup.createdDate).add(3, "hour").format("DD.MM.YYYY")
            : "",
        } as any;
      });
      setInitialRecords(forms);
    }
  }, [getFieldGroupData]);

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
      setFetching(false);
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)

      const filteredData = initialRecords.filter(({ name, createdDate }) => {
        if (
          debouncedNameQuery !== "" &&
          nameQuery &&
          !`${name}`
            .toLowerCase()
            .replaceAll("i̇", "i")
            .includes(debouncedNameQuery.trim().toLowerCase())
        ) {
          return false;
        }

        if (
          debouncedCreatedDateQuery !== "" &&
          createdDateQuery &&
          createdDate !== debouncedCreatedDateQuery
        ) {
          return false;
        }

        return true;
      });

      let sortedData = [...filteredData];
      if (sortStatus.columnAccessor === "name") {
        sortedData.sort((a, b) => {
          const valueA = a.name ? a.name : "";
          const valueB = b.name ? b.name : "";
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "createdDate") {
        sortedData.sort((a, b) => {
          const dateA = a.createdDate
            ? moment(a.createdDate, "DD.MM.YYYY")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan
          const dateB = b.createdDate
            ? moment(b.createdDate, "DD.MM.YYYY")
            : moment(0); // Eğer tarih yoksa, epoch zamanını kullan

          return sortStatus.direction === "asc"
            ? dateA.diff(dateB)
            : dateB.diff(dateA);
        });
      }
      setSortedData(sortedData);
      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [initialRecords, page, sortStatus, triggerSearch]);

  const handleClearFilters = () => {
    setNameQuery("");
    setCreatedDateQuery("");
    setSortStatus({
      columnAccessor: "createdDate",
      direction: "desc",
    });
    setTimeout(() => {
      setTriggerSearch(Math.random().toString(36).substring(7));
    }, 250);
  };

  const handleButtonClick = () => {
    setName("");
    setShouldUpdate(false);
    setOpenNoteAddDrawer(true);
  };

  const handleSubmit = async () => {
    setNameBlured(true);
    if (!name) {
      return;
    }

    setFetching(true);

    var obj = {} as any;

    obj.groupName = name;

    if (shouldUpdate) {
      obj.id = editId;
    }
    if (shouldUpdate) {
      let responseUpdate = await dispatch(
        UpdateFieldGroupService(obj)
      ).unwrap();
      handleResponse(responseUpdate);
    } else {
      let responseAdd = await dispatch(CreateFieldGroupService(obj)).unwrap();
      handleResponse(responseAdd);
    }

    setOpenNoteAddDrawer(false);
  };

  const handleResponse = (response: any) => {
    if (response?.success) {
      Swal.fire({
        icon: "success",
        confirmButtonColor: "#007aff",
        confirmButtonText: "Tamam",
        width: 400,
        text: "Grup başarı ile kayıt edildi.",
      }).then(function () {
        dispatch(GetFieldGroupService());
        setNameBlured(false);
      });
    } else {
      Swal.fire({
        icon: "warning",
        confirmButtonColor: "#007aff",
        confirmButtonText: "Tamam",
        width: 400,
        text: response?.userMessage || "Bir hata oluştu.",
      }).then(function () {
        setFetching(false);
      });
    }
  };

  const columns = [
    {
      accessor: "name",
      title: "Grup Adı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: (
        <TextInput
          label="Grup Adı"
          description="Belirtilen grup adı ile ara."
          placeholder="Grup adı ara..."
          icon={<IconSearch size={16} />}
          value={nameQuery}
          onChange={(e) => setNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: nameQuery !== "",
    },
    {
      accessor: "createdDate",
      title: "Oluşturulma Tarihi",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      filter: ({ close }: { close: any }) => (
        <DatesProvider
          settings={{
            locale: dayjs.locale("tr"),
            firstDayOfWeek: 1,
            weekendDays: [0, 6],
          }}
        >
          <DatePicker
            maxDate={new Date()}
            value={
              createdDateQuery == ""
                ? null
                : moment(createdDateQuery, "DD.MM.YYYY").toDate()
            }
            onChange={(date) =>
              setCreatedDateQuery(moment(date).format("DD.MM.YYYY"))
            }
          />
          <Button
            disabled={createdDateQuery == ""}
            color="red"
            onClick={() => {
              setCreatedDateQuery("");
              close();
            }}
          >
            Reset
          </Button>
        </DatesProvider>
      ),
      filtering: createdDateQuery !== "",
    },
    {
      accessor: "isRead",
      title: "Aksiyon",
      textAlignment: "left" as DataTableColumnTextAlignment,

      render: (record: any, index: number) => (
        <Group spacing={4} position="center" noWrap>
          <>
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#000000",
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "100%",
                fontWeight: 300,
              }}
              onClick={() => {
                setEditId(record.id);
                setName(record.name);
                setShouldUpdate(true);
                setOpenNoteAddDrawer(true);
              }}
            >
              Düzenle
            </Button>
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#000000",
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "100%",
                fontWeight: 300,
              }}
              onClick={() => {
                deleteFormGroupHandler(record.id);
              }}
            >
              Sil
            </Button>
          </>
        </Group>
      ),
    },
  ];

  const deleteFormGroupHandler = (id: string) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Grup adı ve bağlı olan tüm tarlalardan seçili grup adı silinecektir, işleme devam edilsin mi?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0097c4",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet!",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFormGroup(id);
      }
    });
  };

  const deleteFormGroup = async (id: string) => {
    let response = await dispatch(
      DeleteFieldGroupService({
        id: id,
      } as DeleteFieldGroupRequest)
    ).unwrap();
    if (response?.success) {
      Swal.fire({
        title: "Grup başarı ile silinmiştir.",
        icon: "success",
        confirmButtonColor: "#0097c4",
        confirmButtonText: "Tamam",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(GetFieldGroupService());
        }
      });
    } else {
      Swal.fire({
        title: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        icon: "warning",
        confirmButtonColor: "#0097c4",
        confirmButtonText: "Tamam",
      });
    }
  };

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Grup Yönetimi", href: "#" }]}
        title="Grup Yönetimi"
      >
        <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
          <Suspense fallback={<CustomLoadingOverlay />}>
            <Paper p="xl" className="profile-paper" pos={"relative"}>
              <Grid>
                <Grid.Col lg={12} md={12}>
                  <Flex justify={"flex-end"}>
                    <Button
                      onClick={handleSearch}
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      <IconSearch height={15} /> Filtreleri Uygula
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      onClick={handleClearFilters}
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      <IconClearAll /> Filtreleri Temizle
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      style={{
                        backgroundColor: "#0097c4",
                        paddingBottom: "11.5px",
                        paddingTop: "11.5px",
                        width: "150px",
                        borderRadius: 32,
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontSize: "12px",
                        alignItems: "right",
                        fontFamily: "Poppins-Medium",
                      }}
                      onClick={handleButtonClick}
                    >
                      <IconPlus /> Grup Ekle
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
              <Space h="lg" />
              <Grid>
                <Grid.Col span={12}>
                  <DataTable
                    withColumnBorders
                    highlightOnHover
                    minHeight={250}
                    noRecordsText="Kayıt Bulunamadı..."
                    className="datatable"
                    records={pageData}
                    columns={columns}
                    totalRecords={sortedData.length}
                    recordsPerPage={PAGE_SIZE}
                    page={page}
                    onPageChange={(p) => setPage(p)}
                    fetching={fetching}
                    loaderColor="#0097c4"
                    sortStatus={sortStatus}
                    onSortStatusChange={handleSortStatusChange}
                    sortIcons={{
                      sorted: <IconSortDescending size={14} />,
                      unsorted: <IconSortDescending size={14} />,
                    }}
                  />
                </Grid.Col>
              </Grid>

              <Group>
                <Drawer.Root
                  opened={openNoteAddDrawer}
                  onClose={() => setOpenNoteAddDrawer(false)}
                  position="right"
                >
                  <Drawer.Content
                    style={{
                      paddingLeft: "40px",
                      paddingRight: "40px",
                      width: 1000,
                    }}
                  >
                    <Drawer.Header>
                      <Drawer.Title
                        style={{ font: "Poppins-Light", fontSize: "28px" }}
                      >
                        {shouldUpdate ? "Grup Düzenle" : "Grup Ekle"}
                      </Drawer.Title>
                      <Drawer.CloseButton />
                    </Drawer.Header>
                    <Space h="20px" />
                    <Grid>
                      <Col span={12}>
                        <Text
                          style={{
                            fontStyle: "bold",
                            fontSize: "14px",
                            fontFamily: "Poppins-Medium",
                            color: "#136583",
                          }}
                        >
                          {" "}
                          Grup Adı
                        </Text>
                        <TextInput
                          tabIndex={1}
                          placeholder="Grup adı giriniz"
                          onChange={(event) => setName(event.target.value)}
                          value={name}
                          sx={{
                            Input: {
                              height: "48px",
                              borderRadius: "18px",
                            },
                          }}
                        />
                        {nameBlured && !name && (
                          <Text
                            style={{
                              fontStyle: "bold",
                              fontSize: "12px",
                              fontFamily: "Poppins-Medium",
                              color: "red",
                            }}
                          >
                            Grup adı boş olamaz
                          </Text>
                        )}
                      </Col>

                      <Col span={12}>
                        <Space h="10px" />
                        <Col>
                          <Button
                            onClick={() => {
                              handleSubmit();
                            }}
                            style={{
                              backgroundColor: "#0097c4",
                              paddingBottom: "11.5px",
                              paddingTop: "11.5px",
                              width: "100%",
                              borderRadius: 32,
                              color: "#FFFFFF",
                              textAlign: "center",
                              fontSize: "12px",
                              alignItems: "center",
                              fontFamily: "Poppins-Medium",
                            }}
                          >
                            Kaydet
                          </Button>

                          <Space h="20px" />
                        </Col>
                      </Col>
                    </Grid>
                  </Drawer.Content>
                </Drawer.Root>
              </Group>
            </Paper>
          </Suspense>
        </Paper>
      </MainWrapper>
    </Suspense>
  );
};

export default FieldGroups;
