import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loggerMiddleware } from "../../common/domain/middleware/loggerMiddleware";
import { ModuleMiddleware } from "./ModuleMiddleware";
import {
  AnswerFormAddSlice,
  AnswerListFormSlice,
  AnswerReportFormExcelSlice,
  AnswerReportFormSlice,
  AssignFormDeleteSlice,
  AssignFormListSlice,
  AssignFormSlice,
  AssingFormDetailSlice,
  ClearAnswersSlice,
  CompleteFormTaskSlice,
  CreateFormTaskSlice,
  DeleteFormTaskSlice,
  DetailFormTaskSlice,
  FormAddSlice,
  FormDetailSlice,
  FormListSlice,
  FormPublishSlice,
  FormTaskLocationCheckingSlice,
  FormUpdateSlice,
  GetFormTaskListSlice,
  QuestionFormReportSlice,
  UpdateFormTaskSlice,
  UserPhoneCheckSlice,
} from "../interractor/SurveyInterractor";
import EmployeeInteractor from "../../users/interactor/EmployeeInteractor";
import MyFieldInteractor from "../../myfields/interactor/MyFieldInteractor";
import { GetFieldGroupSlice } from "../../fieldgroups/interactor/FieldGroupInteractor";

const rootReducer = combineReducers({
  FormListState: FormListSlice.reducer,
  AddFormState: FormAddSlice.reducer,
  UpdateFormState: FormUpdateSlice.reducer,
  FormDetailState: FormDetailSlice.reducer,
  FormPublishState: FormPublishSlice.reducer,
  EmployeeState: EmployeeInteractor,
  MyFieldState: MyFieldInteractor,
  AssignFormListState: AssignFormListSlice.reducer,
  AssignFormState: AssignFormSlice.reducer,
  AssignFormDeleteState: AssignFormDeleteSlice.reducer,
  QuestionFormReportState: QuestionFormReportSlice.reducer,
  AnswerFormAddState: AnswerFormAddSlice.reducer,
  AnswerListFormState: AnswerListFormSlice.reducer,
  AnswerReportFormState: AnswerReportFormSlice.reducer,
  ClearAnswersState: ClearAnswersSlice.reducer,
  AnswerReportFormExcel: AnswerReportFormExcelSlice.reducer,
  UserPhoneCheckState: UserPhoneCheckSlice.reducer,
  GetFormTaskListState: GetFormTaskListSlice.reducer,
  DetailFormTaskState: DetailFormTaskSlice.reducer,
  CreateFormTaskState: CreateFormTaskSlice.reducer,
  UpdateFormTaskState: UpdateFormTaskSlice.reducer,
  DeleteFormTaskState: DeleteFormTaskSlice.reducer,
  AssingFormDetailState: AssingFormDetailSlice.reducer,
  FormTaskLocationCheckingState: FormTaskLocationCheckingSlice.reducer,
  CompleteFormTaskState: CompleteFormTaskSlice.reducer,
  GetFieldGroupState: GetFieldGroupSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ModuleMiddleware,
      loggerMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;
