import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { ModuleMiddleware } from "./ModuleMiddleware";
import SatelliteInteractor, {
  AgroNotesSlice,
  getAgroNotes,
} from "../interactor/SatelliteInteractor";
import {
  agrovisioFieldDatesSlice,
  agrovisioFieldImageSlice,
  agrovisioImageTypesSlice,
  corporateSatelliteSlice,
  getFieldInfoSlice,
} from "../../fielddetail/interactor/FieldDetailInteractor";
import MyFieldInteractor from "../../myfields/interactor/MyFieldInteractor";
import { GetFieldGroupSlice } from "../../fieldgroups/interactor/FieldGroupInteractor";
const rootReducer = combineReducers({
  satelliteState: SatelliteInteractor,
  fieldState: MyFieldInteractor,
  getFieldInfoState: getFieldInfoSlice.reducer,
  getNoteAgroState: AgroNotesSlice.reducer,
  agrovisioFieldImageState: agrovisioFieldImageSlice.reducer,
  agrovisioImageTypes: agrovisioImageTypesSlice.reducer,
  agrovisioFieldDates: agrovisioFieldDatesSlice.reducer,
  corporateSatellite: corporateSatelliteSlice.reducer,
  GetFieldGroupState: GetFieldGroupSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ModuleMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;
