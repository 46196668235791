// eslint-disable-next-line
import {
  Button,
  Container,
  Grid,
  Group,
  Paper,
  Space,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { IconSearch, IconSortDescending } from "@tabler/icons-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import React, { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useAppDispatch } from "../../connector/ModuleHook";
import { RootState } from "../../connector/ModuleStore";
import { GetProfileResponse } from "../../data/response/GetProfileResponse";
import { UserProfileService } from "../../interactor/GetUserProfileInteractor";
import { toast } from "react-toastify";

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);

const PAGE_SIZE = 10;

interface MyFieldTable {
  fieldName: string;
  city: string;
  province: string;
  street: string;
}

const UserProfile: React.FC = () => {
  const [initialRecords, setInitialRecords] = useState<MyFieldTable[]>([]);
  const [fieldName] = useState("");
  const [fieldNameQuery, setFieldNameQuery] = useState("");
  const [debouncedFieldNameQuery] = useDebouncedValue(fieldNameQuery, 200);
  const [cityQuery, setCityQuery] = useState("");
  const [debouncedCityQuery] = useDebouncedValue(cityQuery, 200);
  const [provinceQuery, setProvinceQuery] = useState("");
  const [debounceProvinceQuery] = useDebouncedValue(provinceQuery, 200);
  const [streetQuery, setStreetQuery] = useState("");
  const [debouncedStreet] = useDebouncedValue(streetQuery, 200);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState<MyFieldTable[]>([]);
  const [selectedFields] = useState<string[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);

  const [userProfileData, setUserProfileData] = useState<any>();
  const [fieldData, setFieldData] = useState<any>();

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "date",
    direction: "desc",
  });
  const handleSortStatusChange = (updatedSortStatus: DataTableSortStatus) => {
    setSortStatus(updatedSortStatus);
  };
  const dispatch = useAppDispatch();

  const userProfileService = useSelector(
    (state: RootState) => state.getUserProfileState.value
  );

  const myFieldsService = useSelector(
    (state: RootState) => state.myFieldState.value
  );

  useEffect(() => {
    setUserProfileData(userProfileService?.data?.employee);
  }, [userProfileService]);

  useEffect(() => {
    setFieldData(myFieldsService?.data.fields);
  }, [myFieldsService]);

  useEffect(() => {
    dispatch(MyFieldService()).then((respose) => {
      let payload = respose.payload as GetProfileResponse | undefined;
      if (payload?.success) {
        setFetching(false);
      } else {
        setFetching(false);
        toast.error(payload?.userMessage);
      }
    });
  }, []);

  useEffect(() => {
    dispatch(UserProfileService()).then((respose) => {
      let payload = respose.payload as GetProfileResponse | undefined;
      if (payload?.success) {
      } else {
        toast.error(payload?.userMessage);
      }
    });
  }, []);

  useEffect(() => {
    if (fieldData) {
      const myFieldDataFiltered = fieldData.map((field: any) => {
        return {
          fieldName: field.fieldName,
          city: field.address.city.name,
          province: field.address.district.name,
          street: field.address.neighbourhood.name,
        };
      });
      setInitialRecords(myFieldDataFiltered);
    }
  }, [fieldData]);

  const form = useForm({
    initialValues: {
      name: "",
      lastName: "",
      phoneNumber: "",
      authorizationType: "",
    },

    validate: {
      name: (value) => (value.trim() !== "" ? null : "Name is required"),
      lastName: (value) =>
        value.trim() !== "" ? null : "Last Name is required",
      phoneNumber: (value) =>
        value.trim() !== "" ? null : "Phone Number is required",
      authorizationType: (value) =>
        value.trim() !== "" ? null : "Authorization is required",
    },
  });

  const from = (page - 1) * PAGE_SIZE;
  const to = from + PAGE_SIZE;

  useEffect(() => {
    if (initialRecords) {
      setPageData(initialRecords.slice(from, to));
    }
    const applySortingAndFiltering = () => {
      // Filtering Logic (Retained completely)
      const filteredData = initialRecords.filter(
        ({ fieldName, city, province, street }) => {
          if (
            debouncedFieldNameQuery !== "" &&
            fieldName &&
            !`${fieldName}`
              .toLowerCase()
              .includes(debouncedFieldNameQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debouncedCityQuery !== "" &&
            city &&
            !`${city}`
              .toLowerCase()
              .includes(debouncedCityQuery.trim().toLowerCase())
          ) {
            return false;
          }

          if (
            debounceProvinceQuery !== "" &&
            province &&
            !`${province}`
              .toLowerCase()
              .includes(debounceProvinceQuery.trim().toLowerCase())
          ) {
            return false;
          }
          if (
            debouncedStreet !== "" &&
            street &&
            !`${street}`
              .toLowerCase()
              .includes(debouncedStreet.trim().toLowerCase())
          ) {
            return false;
          }

          return true;
        }
      );

      let sortedData = [...filteredData];

      if (sortStatus.columnAccessor === "fieldName") {
        sortedData.sort((a, b) => {
          const valueA = a.fieldName;
          const valueB = b.fieldName;
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "city") {
        sortedData.sort((a, b) => {
          const valueA = a.city.toLowerCase();
          const valueB = b.city.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "province") {
        sortedData.sort((a, b) => {
          const valueA = a.province.toLowerCase();
          const valueB = b.province.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      } else if (sortStatus.columnAccessor === "street") {
        sortedData.sort((a, b) => {
          const valueA = a.street.toLowerCase();
          const valueB = b.street.toLowerCase();
          return sortStatus.direction === "asc"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        });
      }

      setPageData(sortedData.slice(from, to));
    };

    applySortingAndFiltering();
  }, [
    debouncedFieldNameQuery,
    debouncedCityQuery,
    debounceProvinceQuery,
    debouncedStreet,
    page,
    sortStatus,
    initialRecords,
  ]);

  const columns = [
    {
      accessor: "fieldName",
      title: "Tarla Adı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ fieldName }: { fieldName: string }) => `${fieldName}`,
      filter: (
        <TextInput
          label="İsim"
          description="Tarla adlarını ara."
          placeholder="Tarla adı ara..."
          icon={<IconSearch size={16} />}
          value={fieldNameQuery}
          onChange={(e) => setFieldNameQuery(e.currentTarget.value)}
        />
      ),
      filtering: fieldNameQuery !== "",
    },
    {
      accessor: "city",
      title: "İl",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ city }: { city: string }) => `${city}`,
      filter: (
        <TextInput
          label="Şehir"
          description="Belirtilen şehirdeki tarlaları ara."
          placeholder="Şehre göre filtrele"
          icon={<IconSearch size={16} />}
          value={cityQuery}
          onChange={(e) => setCityQuery(e.currentTarget.value)}
        />
      ),
      filtering: cityQuery !== "",
    },
    {
      accessor: "province",
      title: "İlçe",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ province }: { province: string }) => `${province}`,
      filter: (
        <TextInput
          label="İlçe"
          description="Belirtilen ilçedeki tarlaları ara."
          placeholder="İlçeye göre filtrele"
          icon={<IconSearch size={16} />}
          value={provinceQuery}
          onChange={(e) => setProvinceQuery(e.currentTarget.value)}
        />
      ),
      filtering: provinceQuery !== "",
    },
    {
      accessor: "street",
      title: "Mahalle/Köy",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ street }: { street: string }) => `${street}`,
      filter: (
        <TextInput
          label="Mahalle/Köy"
          description="Belirtilen mahalle/köydeki tarlaları ara."
          placeholder="Mahalle/Köy'e göre filtrele"
          icon={<IconSearch size={16} />}
          value={streetQuery}
          onChange={(e) => setStreetQuery(e.currentTarget.value)}
        />
      ),
      filtering: streetQuery !== "",
    },
  ];

  const setAuthorityName = (authorityType: string) => {
    if (authorityType === "1") {
      return "Yetkili";
    } else if (authorityType === "2") {
      return "Mühendis";
    } else if (authorityType === "3") {
      return "Üretici";
    }
  };

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        profile=""
        breadCrumbs={[{ title: "Profilim", href: "#" }]}
        title="Profilim"
      >
        <Suspense fallback={<CustomLoadingOverlay />}>
          <Paper
            shadow="xs"
            p="xl"
            pt="0"
            className="profile-paper user-profile"
          >
            <Container fluid>
              <Grid align="center">
                <Grid.Col span={12} md={5}>
                  <form
                    onSubmit={form.onSubmit((values) => {})}
                    className="user-profile-form"
                  >
                    <TextInput
                      label="Adı"
                      {...form.getInputProps("profileName")}
                      value={userProfileData?.firstName}
                      disabled
                    />
                    <Space h={"md"}></Space>
                    <TextInput
                      label="Soyadı"
                      {...form.getInputProps("lastName")}
                      value={userProfileData?.lastName}
                      disabled
                    />
                    <Space h={"md"}></Space>
                    <TextInput
                      label="Cep Telefonu"
                      {...form.getInputProps("phoneNumber")}
                      value={
                        (userProfileData?.phoneNumber?.countryCode ?? "") +
                        (userProfileData?.phoneNumber?.phoneNumber ?? "")
                      }
                      disabled
                    />
                    <Space h={"md"}></Space>
                    <TextInput
                      label="Yetki"
                      {...form.getInputProps("authorizationType")}
                      value={setAuthorityName(
                        userProfileData?.authorityType.toString() ?? ""
                      )}
                      disabled
                    />
                    <Space h={"md"}></Space>
                    <TextInput
                      label="Toplam Tarla Sayısı"
                      {...form.getInputProps("phoneNumber")}
                      value={initialRecords.length}
                      disabled
                    />
                    <Space h={"md"}></Space>
                    <Group position="right" mt="md" display={"none"}>
                      <Button type="submit">Gönder</Button>
                    </Group>
                  </form>
                </Grid.Col>
              </Grid>
              <Grid align="center">
                <Grid.Col span={12}>
                  <Stack>
                    <Text fz={".95rem"} ff={"Poppins-medium"}>
                      Tarlalarım
                    </Text>
                    <DataTable
                      withColumnBorders
                      highlightOnHover
                      minHeight={250}
                      noRecordsText="Tarla Bulunamadı..."
                      className="datatable"
                      records={pageData || []}
                      columns={columns}
                      totalRecords={initialRecords.length}
                      recordsPerPage={PAGE_SIZE}
                      fetching={fetching}
                      loaderColor="#0097c4"
                      page={page}
                      onPageChange={(p) => setPage(p)}
                      sortStatus={sortStatus}
                      onSortStatusChange={handleSortStatusChange}
                      sortIcons={{
                        sorted: <IconSortDescending size={14} />,
                        unsorted: <IconSortDescending size={14} />,
                      }}
                    />
                  </Stack>
                </Grid.Col>
              </Grid>
            </Container>
          </Paper>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
};

export default UserProfile;
