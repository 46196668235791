import { Provider } from "react-redux";
import { Route, Routes } from "react-router";
import { ModuleStore } from "./ModuleStore";
import FieldGroups from "../presentation/FieldGroups";

export default function FieldGroupRoutes() {
  return (
    <Provider store={ModuleStore}>
      <Routes>
        <Route path="/fieldgroup/list" element={<FieldGroups />} />
      </Routes>
    </Provider>
  );
}
