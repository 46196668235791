import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { ModuleMiddleware } from "./ModuleMiddleware";
import {
  ApprovedContractsSlice,
  MyFieldSlice,
  profileSlice,
  userProfileSlice,
} from "../interactor/ProfileInteractor";
import { loggerMiddleware } from "../../common/domain/middleware/loggerMiddleware";
const rootReducer = combineReducers({
  getProfileInfoState: profileSlice.reducer,
  getUserProfileInfoState: userProfileSlice.reducer,
  getFieldInfoState: MyFieldSlice.reducer,
  getApprovedContractsState: ApprovedContractsSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ModuleMiddleware,
      loggerMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;
