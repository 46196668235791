import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { ModuleMiddleware } from "./ModuleMiddleware";
import { loggerMiddleware } from "../../common/domain/middleware/loggerMiddleware";
import EmployeeInteractor from "../../users/interactor/EmployeeInteractor";
import MyFieldInteractor from "../../myfields/interactor/MyFieldInteractor";

const rootReducer = combineReducers({
  EmployeeState: EmployeeInteractor,
  MyFieldState: MyFieldInteractor,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ModuleMiddleware,
      loggerMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;
