// eslint-disable-next-line
import {
  Button,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  TextInput,
} from "@mantine/core";
import {
  IconClearAll,
  IconSearch,
  IconSortDescending,
} from "@tabler/icons-react";
import {
  DataTable,
  DataTableColumnTextAlignment,
  DataTableSortStatus,
} from "mantine-datatable";
import React, { Suspense, lazy, useEffect, useState } from "react";
import CustomLoadingOverlay from "../../../utils/CustomLoadingOverlay";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../../modules/notifications/connector/ModuleHook";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../../connector/ModuleStore";
import { NotificationResponse } from "../../data/response/NotificationResponse";
import { NotificationUpdateRequest } from "../../data/request/NotificationUpdateRequest";
import { notificationIsReadUpdate } from "../../interactor/NotificatiınIsReadUpdateInteractor";
import { notificationGetCorporateInbox } from "../../interactor/NotificationInteractor";
import { CorporateInboxDto } from "../../data/dto/NotificationDto";
import { GetFieldGroupService } from "../../../fieldgroups/interactor/FieldGroupInteractor";
import { MyFieldService } from "../../../myfields/interactor/MyFieldInteractor";

const MainWrapper = lazy(
  () => import("../../../../app/common/presentation/view/component/mainWrapper")
);
const NotificationModal = lazy(() => import("../components/NotificationModal"));
const NotificationType = lazy(() => import("../components/NotificationType"));

interface Field {
  id: string;
  fieldDate: Date;
  fieldName: string;
  body: string;
  engineer: string;
  message: string;
  isRead: number;
  notificationKind: number;
}

const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate(); // useNavigate hook'u tanımlandı
  const [searchParams, setSearchParams] = useSearchParams();

  const [query, setQuery] = useState(new Map<string, string>());

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "createdDate",
    direction: "desc",
  });

  const [requested, setRequested] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Field | null>(null);

  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [records, setRecords] = useState<CorporateInboxDto[]>();

  const notificationService = useSelector(
    (state: RootState) => state.getNotificationState
  );

  const getFieldGroupService = useSelector(
    (state: RootState) => state.GetFieldGroupState.value
  );

  const getFieldGroupData = getFieldGroupService?.data?.fieldGroup;

  const myFieldService = useSelector(
    (state: RootState) => state.myFieldState.value
  );

  const myFieldData = myFieldService?.data.fields;

  useEffect(() => {
    dispatch(MyFieldService());
    dispatch(GetFieldGroupService());
  }, []);

  const [triggerSearch, setTriggerSearch] = useState("");
  const handleSearch = () => {
    setTriggerSearch(Math.random().toString(36).substring(7));
  };

  const handleIsRead = (record: Field) => {
    if (record.isRead === 0) {
      dispatch(
        notificationIsReadUpdate(
          new NotificationUpdateRequest({
            id: record.id,
            corporateInboxStatus: 1,
          })
        )
      ).then((response) => {
        if (response !== undefined) {
          const payload = response.payload as NotificationResponse | undefined;
          if (payload?.success) {
            toast.success("Okundu olarak işaretlendi.");

            if (records) {
              var tempRecords = [...records];
              var temp = records?.findIndex((x) => x.id == record.id);
              if (temp > -1) {
                tempRecords[temp] = {
                  ...records[temp],
                  corporateInboxStatus: 1,
                };
                setRecords(tempRecords);
              }
            }
          } else {
            toast.error("Okundu olarak işaretlenemedi.");
          }
        }
      });
    }
  };

  const onFilterSubmit = (form: {
    field: string | null;
    notificationType: string | null;
    fieldGroup: string | null;
  }) => {
    var queryMap = new Map(query);
    if ((form.field?.length ?? 0) == 0) {
      queryMap.delete("FieldId");
    } else {
      queryMap.set("FieldId", form.field ?? "");
    }

    if ((form.notificationType?.length ?? 0) == 0) {
      queryMap.delete("CorporateNotificationKind");
    } else {
      queryMap.set("CorporateNotificationKind", form.notificationType ?? "");
    }
    if ((form.fieldGroup?.length ?? 0) == 0) {
      queryMap.delete("FieldGroupId");
    } else {
      queryMap.set("FieldGroupId", form.fieldGroup ?? "");
    }
    setQuery(queryMap);
  };

  useEffect(() => {
    let field = searchParams.get("fieldId");
    let notificationType = searchParams.get("kind");
    let fieldGroup = searchParams.get("fieldGroup");

    if (!requested && (field || notificationType || fieldGroup)) {
      onFilterSubmit({
        field: field,
        notificationType: notificationType,
        fieldGroup: fieldGroup,
      });
    } else {
      dispatch(
        notificationGetCorporateInbox({
          query: query,
          sortStatus: sortStatus,
          Page: page,
          Size: pageSize,
        })
      );
    }
    setRequested(true);
  }, [page, query, sortStatus, pageSize, searchParams, triggerSearch]);

  useEffect(() => {
    setRecords(notificationService?.value?.data?.corporateInbox);
  }, [notificationService?.value?.data?.corporateInbox]);

  useEffect(() => {
    let field = searchParams.get("fieldId");
    let notificationType = searchParams.get("kind");
  }, [searchParams]);

  const handleClearFilters = () => {
    setQuery(new Map<string, string>());
    setSearchParams("");
    setTimeout(() => {
      setTriggerSearch(Math.random().toString(36).substring(7));
    }, 250);
  };

  const columns = [
    {
      accessor: "createdDate",
      title: "Tarih",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ fieldDate }: { fieldDate: Date }) => {
        const localDate = new Date(fieldDate);
        localDate.setHours(localDate.getHours() + 3);
        const options: Intl.DateTimeFormatOptions = {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return localDate.toLocaleString("tr-TR", options);
      },
    },
    {
      accessor: "fieldName",
      title: "Tarla Adı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ fieldName }: { fieldName: string }) => `${fieldName}`,
      filter: (
        <TextInput
          label="Tarla Adı"
          description="Belirtilen adı içeren tarlaları ara."
          placeholder="Tarla ara..."
          icon={<IconSearch size={16} />}
          value={query.get("FieldName")}
          onChange={(e) => {
            var queryMap = new Map(query);
            if (e.currentTarget.value.length == 0) {
              queryMap.delete("FieldName");
            } else {
              queryMap.set("FieldName", e.currentTarget.value);
            }

            setQuery(queryMap);
          }}
        />
      ),
    },
    {
      accessor: "CorporateEmployeeName",
      render: ({ engineer }: { engineer: string }) => `${engineer}`,
      title: "Ziraat Mühendisi",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: "Message",
      title: "Mesaj",
      textAlignment: "left" as DataTableColumnTextAlignment,
      sortable: true,
      ellipsis: true,
      render: ({ message }: { message: string }) => `${message}`,
    },
    {
      accessor: "isRead",
      title: "Okundu / Okunmadı",
      textAlignment: "left" as DataTableColumnTextAlignment,
      render: (record: Field) => (
        <Group spacing={4} position="center" noWrap>
          {record.isRead ? (
            <Button
              variant="outline"
              color="#136583"
              fullWidth
              style={{
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "100%",
                fontWeight: 300,
                borderWidth: 0,
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setIsModalOpen(true);
                handleIsRead(record);
              }}
            >
              Okundu
            </Button>
          ) : (
            <Button
              variant="outline"
              color="#000000"
              fullWidth
              style={{
                color: "#000000",
                borderColor: "#000000",
                borderRadius: "2rem",
                width: "100%",
                fontWeight: 300,
              }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                setSelectedRecord(record);
                setIsModalOpen(true);
                handleIsRead(record);
              }}
            >
              Oku
            </Button>
          )}
        </Group>
      ),
    },
  ];

  return (
    <Suspense fallback={<CustomLoadingOverlay />}>
      <MainWrapper
        breadCrumbs={[{ title: "Bildirimlerim", href: "#" }]}
        title="Bildirimler"
      >
        <Suspense fallback={<CustomLoadingOverlay></CustomLoadingOverlay>}>
          <LoadingOverlay
            visible={notificationService.isLoading ?? false}
            overlayBlur={2}
            loaderProps={{
              size: "md",
              color: "#0097c4",
              variant: "oval",
            }}
            pos={"absolute"}
          />
          <Paper shadow="xs" p="xl" pt="0" className="profile-paper">
            {/* NotificationType component */}
            {myFieldData && getFieldGroupData && (
              <NotificationType
                fieldGroup={getFieldGroupData}
                fields={myFieldData}
                onFilterSubmit={onFilterSubmit}
              ></NotificationType>
            )}
            <Space h="md" />
            {/* DataTable component */}

            <Grid>
              <Grid.Col lg={12} md={12}>
                <Flex justify={"flex-end"}>
                  <Button
                    onClick={handleSearch}
                    style={{
                      backgroundColor: "#0097c4",
                      paddingBottom: "11.5px",
                      paddingTop: "11.5px",
                      width: "150px",
                      borderRadius: 32,
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "12px",
                      alignItems: "right",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    <IconSearch height={15} /> Filtreleri Uygula
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={handleClearFilters}
                    style={{
                      backgroundColor: "#0097c4",
                      paddingBottom: "11.5px",
                      paddingTop: "11.5px",
                      width: "150px",
                      borderRadius: 32,
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "12px",
                      alignItems: "right",
                      fontFamily: "Poppins-Medium",
                    }}
                  >
                    <IconClearAll /> Filtreleri Temizle
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
            <Space h="md" />
            <DataTable
              withBorder
              withColumnBorders
              highlightOnHover
              className="datatable"
              minHeight={250}
              noRecordsText="Bildirim Bulunamadı..."
              records={
                records?.map((notification) => {
                  return {
                    id: notification.id || "",
                    fieldDate: notification?.createdDate
                      ? new Date(notification.createdDate)
                      : new Date(),
                    fieldName: notification?.fieldName || "",
                    body: notification?.body || "",
                    engineer: notification?.managers
                      ? notification.managers
                          .map((manager) => manager.employeeName)
                          .join(", ")
                      : "",
                    message: notification?.title || "",
                    isRead: notification?.corporateInboxStatus || 0,
                    notificationKind:
                      notification?.corporateNotificationKind || 0,
                  };
                }) ?? []
              }
              columns={columns}
              loaderColor="#0097c4"
              totalRecords={
                notificationService?.value?.data?.page?.totalCount ?? 0
              }
              recordsPerPage={pageSize}
              recordsPerPageOptions={[5, 10, 20, 50, 100]}
              onPageChange={(p) => setPage(p)}
              onRecordsPerPageChange={(perPage) => {
                setPageSize(perPage);
                setPage(1);
              }}
              page={page}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              sortIcons={{
                sorted: <IconSortDescending size={14} />,
                unsorted: <IconSortDescending size={14} />,
              }}
            />
            {/* Tarla Detaya Git Butonu */}
            <div style={{ textAlign: "center" }}>
              {searchParams.get("fieldId") && (
                <Button
                  color="blue"
                  style={{
                    backgroundColor: "#0097c4", // Corrected syntax
                    marginTop: "1rem",
                    borderRadius: "2rem",
                    width: "30%", // Width as a percentage of the container
                  }}
                  onClick={() =>
                    navigate(
                      `/myfields/detail?id=${searchParams.get("fieldId")}`
                    )
                  }
                >
                  Tarla Detaya Git
                </Button>
              )}
            </div>
            {/* NotificationModal component */}
            <NotificationModal
              field={selectedRecord}
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </Paper>
        </Suspense>
      </MainWrapper>
    </Suspense>
  );
};

export default Notifications;
