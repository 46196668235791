import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { ModuleMiddleware } from "./ModuleMiddleware";
import {
  AddFormFieldVisitorSlice,
  addImageSlice,
  addSeasonNotesSlice,
  addSeasonsSlice,
  agrovisioFieldDatesSlice,
  agrovisioFieldImageSlice,
  agrovisioImageTypesSlice,
  changeFieldInfoSlice,
  changeSeasonInfoSlice,
  closeSeasonSlice,
  corporateSatelliteSlice,
  deleteImageSlice,
  deleteSeasonNote,
  deleteSeasonNoteSlice,
  FieldInformationSlice,
  FormFieldVisitorsSlice,
  getFieldInfoSlice,
  getImagesInfoSlice,
  getNDVIScoreSlice,
  getNotificationsSlice,
  getSeasonNoteTitleSlice,
  getSeasonsInfoSlice,
  getSeasonsNotesInfoSlice,
  getUsersSlice,
  GetWeeklyNotificationSlice,
  updateFieldAnalysisInfoSlice,
  updateSeasonNotesInfoSlice,
  updateSeasonSlice,
} from "../interactor/FieldDetailInteractor";
import {
  weatherTarlaIOSlice,
  weatherHourlyTarlaIOSlice,
  weatherAccuWeatherSlice,
  weatherWeatherBitSlice,
  weatherVisualCrossSlice,
  weatherWeatherAPISlice,
  weatherHourlyAccuWeatherSlice,
  weatherHourlyWeatherBitSlice,
  weatherHourlyVisualCrossSlice,
  weatherHourlyWeatherAPISlice,
} from "../../weather/interactor/WeatherInteractor";
import CultivationInteractor from "../../myfields/interactor/CultivationInteractor";
import PlantInteractor from "../../myfields/interactor/PlantInteractor";
import PlantVariantInteractor from "../../myfields/interactor/PlantVariantInteractor";
import { loggerMiddleware } from "../../common/domain/middleware/loggerMiddleware";
import MyFieldInteractor, {
  MyFieldSlice,
} from "../../myfields/interactor/MyFieldInteractor";
import SatelliteInteractor from "../../satellite/interactor/SatelliteInteractor";
import EmployeeInteractor from "../../users/interactor/EmployeeInteractor";
import { profileSlice } from "../../profile/interactor/ProfileInteractor";
import { GetFieldGroupSlice } from "../../fieldgroups/interactor/FieldGroupInteractor";

const rootReducer = combineReducers({
  employeeState: EmployeeInteractor,
  getFieldInfoState: getFieldInfoSlice.reducer,
  getFieldNDVIState: getNDVIScoreSlice.reducer,
  getFieldNotificationState: getNotificationsSlice.reducer,
  getFieldUsersInfoState: getUsersSlice.reducer,
  getSeasonsInfoState: getSeasonsInfoSlice.reducer,
  weatherTarlaIOOState: weatherTarlaIOSlice.reducer,
  weatherHourlyTarlaIOOState: weatherHourlyTarlaIOSlice.reducer,
  cultivationState: CultivationInteractor,
  plantState: PlantInteractor,
  plantTypeState: PlantVariantInteractor,
  getSeasonNotesInfoState: getSeasonsNotesInfoSlice.reducer,
  changeSeasonState: changeSeasonInfoSlice.reducer,
  updateSeasonState: updateSeasonSlice.reducer,
  deleteSeasonNoteState: deleteSeasonNoteSlice.reducer,
  updateSeasonNotesState: updateSeasonNotesInfoSlice.reducer,
  addSeasonNotesState: addSeasonNotesSlice.reducer,
  addSeasonsState: addSeasonsSlice.reducer,
  closeSeasonState: closeSeasonSlice.reducer,
  getImagesState: getImagesInfoSlice.reducer,
  updateFieldAnalysisState: updateFieldAnalysisInfoSlice.reducer,
  myFieldState: MyFieldInteractor,
  satelliteState: SatelliteInteractor,
  weatherAccuState: weatherAccuWeatherSlice.reducer,
  weatherBitState: weatherWeatherBitSlice.reducer,
  weatherVisualCrossState: weatherVisualCrossSlice.reducer,
  weatherApiState: weatherWeatherAPISlice.reducer,
  weatherHourlyAccuState: weatherHourlyAccuWeatherSlice.reducer,
  weatherHourlyBitState: weatherHourlyWeatherBitSlice.reducer,
  weatherHourlyVisualCrossState: weatherHourlyVisualCrossSlice.reducer,
  weatherHourlyApiState: weatherHourlyWeatherAPISlice.reducer,
  addImagesApiState: addImageSlice.reducer,
  deleteImagesState: deleteImageSlice.reducer,
  seasonNoteTitleState: getSeasonNoteTitleSlice.reducer,
  getProfileInfoState: profileSlice.reducer,
  changeFieldInfoState: changeFieldInfoSlice.reducer,
  fieldsInfoState: MyFieldSlice.reducer,
  agrovisioFieldImageState: agrovisioFieldImageSlice.reducer,
  agrovisioImageTypes: agrovisioImageTypesSlice.reducer,
  agrovisioFieldDates: agrovisioFieldDatesSlice.reducer,
  corporateSatellite: corporateSatelliteSlice.reducer,
  AddFormFieldVisitorState: AddFormFieldVisitorSlice.reducer,
  FormFieldVisitorsState: FormFieldVisitorsSlice.reducer,
  fieldInformationState: FieldInformationSlice.reducer,
  GetWeeklyNotificationState: GetWeeklyNotificationSlice.reducer,
  GetFieldGroupState: GetFieldGroupSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      ModuleMiddleware,
      loggerMiddleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;
