import { combineReducers, configureStore } from "@reduxjs/toolkit";
import LoginInteractor, { MyFieldSlice } from "../interactor/MyFieldInteractor";
import { ModuleMiddleware } from "./ModuleMiddleware";
import MyFieldInteractor from "../interactor/MyFieldInteractor";
import AddFieldInteractor from "../interactor/AddFieldInteractor";
import CityInteractor from "../interactor/CityInteractor";
import DistrictInteractor from "../interactor/DistrictInteractor";
import NeighborhoodInteractor from "../interactor/NeighborhoodInteractor";
import CultivationInteractor from "../interactor/CultivationInteractor";
import PlantInteractor from "../interactor/PlantInteractor";
import PlantVariantInteractor from "../interactor/PlantVariantInteractor";
import EmployeeInteractor from "../../users/interactor/EmployeeInteractor";
import DeleteFieldInteractor from "../interactor/DeleteFieldInteractor";
import { changeFieldInfoSlice } from "../../fielddetail/interactor/FieldDetailInteractor";
import { GetFieldGroupSlice } from "../../fieldgroups/interactor/FieldGroupInteractor";
const rootReducer = combineReducers({
  myFieldState: MyFieldInteractor,
  addFieldState: AddFieldInteractor,
  cityState: CityInteractor,
  districtState: DistrictInteractor,
  neigborhoodState: NeighborhoodInteractor,
  cultivationState: CultivationInteractor,
  plantState: PlantInteractor,
  plantTypeState: PlantVariantInteractor,
  managerState: EmployeeInteractor,
  deleteFieldState: DeleteFieldInteractor,
  changeFieldInfoState: changeFieldInfoSlice.reducer,
  employeeState: EmployeeInteractor,
  fieldsInfoState: MyFieldSlice.reducer,
  GetFieldGroupState: GetFieldGroupSlice.reducer,
});

export const ModuleStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(ModuleMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ModuleStore.dispatch;
